import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DateFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { mapState } from 'vuex';
import { isUndefined } from 'lodash';
import ENUM from '@/_store/enum';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

function data() {
  return {
    dataBuque:{
      CountryName:'',
      DatePlanning:'',
      VesselFlagRoute:'',
    },
    items: [],
  }
}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

async function getPdf(rowData, titulo, optionHeaderReport, colDelete) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];
    //if (isUndefined(un)) un = 'TON';

    for (let index = 0; index < this.columnDefs.length; index++) {
        //if (this.columnDefs[index].headerName.toUpperCase() == "WEIGTH") this.columnDefs[index].headerName = this.columnDefs[index].headerName + " (" + un + ")";
        columns.push({
            title: this.columnDefs[index].headerName,
            dataKey: this.columnDefs[index].field
        });
    }

    if (colDelete) {
        columns.splice(colDelete.from-1, colDelete.to);
    }

    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

    let font = 7;
    let widthImage = 80;
    let sizeText = 8;
    let tableWidthBody = 'auto';
    
    if (columns.length < 12) {
        font=7; 
    } else {
        font=5; 
    }

    const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt', 
        format: [612, 792],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        //let fecha = hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        let fecha  = DateFormater.formatTimeZoneToDateTime(hoy);
        doc.setFontSize(font);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed')+": "+ fecha, 40, 570, {
                align: 'left'
            });
            if(this.loadText !== undefined && this.loadText !== null){
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: "+this.loadText, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }else{
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, 585, {           
                align: 'right'
            })
        }
    }

    let voyajeData = '';
    if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
        this.dataBuque.DatePlanning = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName;
        this.dataBuque.CountryName = this.itinerarySelected.CountryName;
        this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        voyajeData = this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
    }

    doc.setDrawColor("#fff");
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    if(this.dataBuque.DatePlanning != ''){
        let dataInfo = [];
        dataInfo.push({ name: this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName });
        dataInfo.push({ name: this.dataBuque.DatePlanning });
        dataInfo.push({ name: voyajeData });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = widthImage+45;
        let ancho = 626;
        doc.autoTable(columns, dataInfo, {
            startY: 60,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    doc.setFontSize(sizeText);
    doc.setFillColor("#cccccc");
    doc.rect(40, 105, 712, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(40, 110, 712, 25, "FD");
    doc.setFontSize(sizeText);

    if(titulo != ''){
        let dataInfo = [];
        dataInfo.push({ name: titulo });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 43;
        let ancho = widthImage+629;
        doc.autoTable(columns, dataInfo, {
            startY: 118,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }
    let inicio = 145;
    doc.autoTable(columns, rowData, {
        startY: inicio,
        theme: 'grid',
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: { bottom: 45 },
        tableWidth: tableWidthBody,
        styles: { 
            cellPadding: 1, 
            fontSize: font, 
            halign: "center", 
            valign: "middle"
        },
        headStyles: {
            fillColor: "#EEE",
            textColor: "#000",
            halign: "center",
        },
        columnStyles: {
            overflow: 'linebreak',
        },
        didParseCell: function (rowData) {
            if(optionHeaderReport == 'containerByPortLineSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];    
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 2){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 3;
                    }
                
                    if(rowData.column.index >= 3 && rowData.column.index <= 4){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 5 && rowData.column.index <= 6){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 7 && rowData.column.index <= 8){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 9 && rowData.column.index <= 10){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 11 && rowData.column.index <= 12){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 13 && rowData.column.index <= 14){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 2;
                    }
                }
            } else if(optionHeaderReport == 'imdgContainerSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 1){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 2;
                    }
                
                    if(rowData.column.index >= 2 && rowData.column.index <= 5){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 4;
                    }
                    
                    if(rowData.column.index >= 6 && rowData.column.index <= 9){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 4;
                    }

                    if(rowData.column.index >= 10 && rowData.column.index <= 13){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 4;
                    }
                }
            } else if(optionHeaderReport == 'delayList'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 12){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 13;
                    }
                
                    if(rowData.column.index >= 13 && rowData.column.index <= 15){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 3;
                    }
                    
                    if(rowData.column.index >= 16 && rowData.column.index <= 18){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 3;
                    }

                    if(rowData.column.index >= 19 && rowData.column.index <= 21){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 3;
                    }
                }
            }  else if(optionHeaderReport == 'transportSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 2){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 3;
                    }
                
                    if(rowData.column.index >= 3 && rowData.column.index <= 8){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 6;
                    }
                    
                    if(rowData.column.index >= 9 && rowData.column.index <= 11){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 3;
                    }

                    if(rowData.column.index >= 12 && rowData.column.index <= 14){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 3;
                    }
                }
            }else if(optionHeaderReport == 'TypeLoadPerCraneSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index == 2){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = rowData.row.raw.DeckMax+2;
                    }
                    
                    if(rowData.column.index >= (4+rowData.row.raw.DeckMax)){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = rowData.row.raw.HoldMax+2;
                    }
                }
            }    
        },
    });
  
    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function getPdfArray(rowData, titulo) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];
    let columnDefs = Object.keys(rowData[0]);
    
    for (let index = 0; index < columnDefs.length; index++) {
        columns.push({
            title: columnDefs[index],
            dataKey: columnDefs[index]
        });
    }
    
    const response1 = await this.$http.getFile('base64', {
        path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;

    let font = 7;
    let widthImage = 80;
    let sizeText = 8;
    let tableWidthBody = 'auto';
    
    if (columns.length < 12) {
        font=7; 
    } else {
        font=5; 
    }
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [612, 792],
        putOnlyUsedFonts: true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        //let fecha = hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        let fecha  = DateFormater.formatTimeZoneToDateTime(hoy);

        doc.setFontSize(font);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed')+": "+ fecha, 40, 570, {
                align: 'left'
            });
            if (this.loadText !== undefined && this.loadText !== null) {
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: " + this.loadText, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            } else {
                doc.text(this.$t('label.user')+": "+ this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, 585, {
                align: 'right'
            })
        }
    }

    let voyajeData = '';
    if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
        this.dataBuque.DatePlanning = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName;
        this.dataBuque.CountryName = this.itinerarySelected.CountryName;
        this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        voyajeData = this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
    }

    doc.setDrawColor("#fff");
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    doc.setDrawColor("#fff");
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    if(this.dataBuque.DatePlanning != ''){
        let dataInfo = [];
        dataInfo.push({ name: this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName });
        dataInfo.push({ name: this.dataBuque.DatePlanning });
        dataInfo.push({ name: voyajeData });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = widthImage+45;
        let ancho = 626;
        doc.autoTable(columns, dataInfo, {
            startY: 60,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    doc.setFontSize(sizeText);
    doc.setFillColor("#cccccc");
    doc.rect(40, 105, 712, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(40, 110, 712, 25, "FD");
    doc.setFontSize(sizeText);

    if(titulo != ''){
        let dataInfo = [];
        dataInfo.push({ name: titulo });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 43;
        let ancho = widthImage+629;
        doc.autoTable(columns, dataInfo, {
            startY: 118,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    //let arreglo = rowData.shift();
    let inicio = 145;
    doc.autoTable(columns, rowData, {
        startY: inicio,
        theme: 'grid',
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        
        tableWidth: tableWidthBody,
        styles: { 
            cellPadding: 1, 
            fontSize: font, 
            halign: "center", 
            valign: "middle"
        },
        headStyles: {
            fillColor: "#EEE",
            textColor: "#000",
            halign: "center",
        },
        columnStyles: {
            overflow: 'linebreak',
        },
    });

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function getExcel(rowData, titulo, excel, CraneName, fromDay, toDay, companyName) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    const hoy = new Date();

    if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }
        
    //if (isUndefined(un)) un = 'TON';
    if (excel) {

        let tituloExcel = "";
        if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
            tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
            this.dataBuque.CountryName = this.itinerarySelected.CountryName;
            this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        }

        if (y < 10) letra = 10;
        worksheet.mergeCells('A1:B2');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B2');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        if(companyName){
            worksheet.mergeCells("C1:" + numeroLetra(y) + "2")   
        }else if(companyName === undefined){ 
            worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
            worksheet.mergeCells("C2:" + numeroLetra(y) + "2");
        }

        const customCellCompanyBranch = worksheet.getCell("C1");

        const row1 = worksheet.getRow(1);
        row1.height = companyName ? 60 : 30;
        customCellCompanyBranch.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCellCompanyBranch.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCellCompanyBranch.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            }
        }
        customCellCompanyBranch.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
        customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            
        if(companyName){
        }else if(companyName === undefined){     
            const row2 = worksheet.getRow(2);    
            row2.height = 30;
            const customCell = worksheet.getCell("C2");
            customCell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCell.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell.value = tituloExcel;
            customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }

        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / 5);
        let rango1 = numeroLetra(division*2);
        let rango11 = numeroLetra((division * 2) +1);
        let rango2 = numeroLetra(division*3);
        let rango21 = numeroLetra((division * 3) +1);
        let rango3 = numeroLetra(division*4);
        let rango31 = numeroLetra((division * 4) +1);
        let rango4 = numeroLetra(y);

        //rango de fechas
        worksheet.mergeCells("A4:" + rango1 +"4");
        const customCell3 = worksheet.getCell("A4");
        customCell3.height = 50;
        worksheet.getRow(4).height = 30;
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = this.$t('label.from')+': '+fromDay+' - '+this.$t('label.to')+': '+toDay;
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //grua
        worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
        const customCell5 = worksheet.getCell(rango11+"4");
        customCell5.height = 50;
        customCell5.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell5.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell5.value = this.$t('label.crane')+': '+CraneName;
        customCell5.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //fecha de impresion
        worksheet.mergeCells(rango21 + "4:" + rango3 + "4");
        const customCell6 = worksheet.getCell(rango21+"4");
        customCell6.height = 50;
        customCell6.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell6.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); //hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        customCell6.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //usuario
        worksheet.mergeCells(rango31 + "4:" + rango4 + "4");
        const customCell7 = worksheet.getCell(rango31+"4");
        customCell7.height = 50;
        customCell7.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        customCell7.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //linea
        worksheet.mergeCells("A5:Q5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
    headerRow.height = 30;
    
    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }
        
        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";
        
        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data; 
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })

    rowData.map(async function(data){
        if (data.Cont20FtDeck=="DECK") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:F7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }            
            cellA4.value = data.Cont20FtDeck;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("G7:J7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont20FtHold;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("K7:N7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont20FtTotal;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

        } else if (data.Cont20Full == "20F") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            //Grupo 1
            worksheet.mergeCells("D7:E7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:G7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("H7:I7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("J7:K7");
            cellA4 = worksheet.getCell("J7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 5
            worksheet.mergeCells("L7:M7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 6
            worksheet.mergeCells("N7:O7");
            cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {            
            for (let  i = 1; i <= 13; i++) { 
                worksheet.mergeCells(numeroLetra(i)+"6:"+numeroLetra(i)+"7");
            }
            for (let  i = 26; i <= 28; i++) { 
                worksheet.mergeCells(numeroLetra(i)+"6:"+numeroLetra(i)+"7");
            } 
            //Grupo 1
            worksheet.mergeCells("N7:P7");
            let cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.CtaFunctionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("Q7:R7");
            cellA4 = worksheet.getCell("R7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.ComAddressIdentifier;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("S7:V7");
            cellA4 = worksheet.getCell("S7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.HanDescriptionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("W7:Y7");
            cellA4 = worksheet.getCell("W7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.TsrServicePriorityCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.section=="totalLoad") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            let Deck_12 = numeroLetra(4+data.DeckMax);
            worksheet.mergeCells("C7:"+Deck_12+"7");

            let cellA4 = worksheet.getCell("C7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                },
            }            
            cellA4.value = "DECK";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            let Hold_1 = numeroLetra(5+data.DeckMax);
            let Hold_12 = numeroLetra(2+data.HoldMax+4+data.DeckMax);
            worksheet.mergeCells(Hold_1+"7:"+Hold_12+"7");
            cellA4 = worksheet.getCell(Hold_1+"7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = "HOLD";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }else {
            let dataRow = worksheet.addRow();
            let indice = 0;
            
            keyColumns.map(function (data2) {               
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })           
        }    
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function getOrdersExcel(rowData, titulo, excel, val, colDelete, tableHeader, companyName) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    const hoy = new Date();
    if (tableHeader) {
        columnsDefs = tableHeader.label;
        keyColumns = tableHeader.key;
        y = columnsDefs.length;
        bandera=false;
    }else if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }

    if(colDelete)
        {
            keyColumns.splice(0, colDelete);
            columnsDefs.splice(0, colDelete);
            y = y-colDelete;
        }
    //if (isUndefined(un)) un = 'TON';
    if (excel) {

        let tituloExcel = "";
        if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
            tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
            this.dataBuque.CountryName = this.itinerarySelected.CountryName;
            this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        }

        if (y < 10) letra = 10;
        worksheet.mergeCells('A1:B2');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B2');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        if(companyName){
            worksheet.mergeCells("C1:" + numeroLetra(y) + "2")   
        }else if(companyName === undefined){ 
            worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
            worksheet.mergeCells("C2:" + numeroLetra(y) + "2");
        }

        const customCellCompanyBranch = worksheet.getCell("C1");

        const row1 = worksheet.getRow(1);
        row1.height = companyName ? 60 : 30;
        customCellCompanyBranch.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCellCompanyBranch.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCellCompanyBranch.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            }
        }
        customCellCompanyBranch.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
        customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            
        if(companyName){
        }else if(companyName === undefined){     
            const row2 = worksheet.getRow(2);    
            row2.height = 40;
            const customCell = worksheet.getCell("C2");
            customCell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCell.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell.value = tituloExcel;
            customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }

        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / val.length);
        let Rango = [];
        let Extra = (y-(division*val.length));
        for (let index = 0 , Num = 1; index < val.length; index++, Num++) {
            if (index < (val.length-1)) {
                if (index==0) {
                    if (Extra>=division) {
                        Num++;
                        Rango.push({
                            rango1: numeroLetra(division*Num),
                            rango11: numeroLetra((division*Num) +1),
                        })
                    }else{
                        Rango.push({
                            rango1: numeroLetra(division),
                            rango11: numeroLetra((division) +1),
                        })
                    }
                }else{
                    Rango.push({
                        rango1: numeroLetra(division*Num),
                        rango11: numeroLetra((division*Num) +1),
                    })
                }    
            }else{
                Rango.push({
                    rango1: numeroLetra(y),
                })
            }
        }
        
        //rango de la filas
        worksheet.getRow(4).height = 35
        worksheet.mergeCells("A4:" + Rango[0].rango1 +"4");
        const customCell3 = worksheet.getCell("A4");
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = val[0];
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        for (let index = 0; index < (Rango.length-1); index++) {
            worksheet.mergeCells(Rango[index].rango11 + "4:" + Rango[index+1].rango1 + "4");
            let Cell = worksheet.getCell(Rango[index].rango11+"4")
            Cell.height = 50;
            Cell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            Cell.value = val[index+1];
            Cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            Cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }
        //linea
        let linea = (Rango.length - 1);
        worksheet.mergeCells("A5:"+Rango[linea].rango1+"5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
    headerRow.height = 30;
    
    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 20;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }
        
        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";
        
        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data; 
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })

    rowData.map(async function(data){
        if (data.Cont20FtDeck=="DECK") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:F7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }            
            cellA4.value = data.Cont20FtDeck;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("G7:J7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont20FtHold;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("K7:N7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont20FtTotal;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

        } else if (data.Cont20Full == "20F") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            //Grupo 1
            worksheet.mergeCells("D7:E7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:G7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("H7:I7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("J7:K7");
            cellA4 = worksheet.getCell("J7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 5
            worksheet.mergeCells("L7:M7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 6
            worksheet.mergeCells("N7:O7");
            cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {        
            for (let  i = 1; i <= 13; i++) { 
                worksheet.mergeCells(numeroLetra(i)+"6:"+numeroLetra(i)+"7");
            }    
            for (let  i = 26; i <= 28; i++) { 
                worksheet.mergeCells(numeroLetra(i)+"6:"+numeroLetra(i)+"7");
            } 
            //Grupo 1
            worksheet.mergeCells("N7:P7");
            let cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.CtaFunctionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("Q7:R7");
            cellA4 = worksheet.getCell("R7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.ComAddressIdentifier;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("S7:V7");
            cellA4 = worksheet.getCell("S7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.HanDescriptionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("W7:Y7");
            cellA4 = worksheet.getCell("W7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.TsrServicePriorityCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.section=="totalLoad") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:E7");
            let cellA4 = worksheet.getCell("C7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                },
            }            
            cellA4.value = "DECK";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:H7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = "HOLD";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }else if (data.section=="transport_summary") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            //Grupo 1
            worksheet.mergeCells("D7:I7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }            
            cellA4.value = data.OrderCantDispatched;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("J7:L7");
            cellA4 = worksheet.getCell("J7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.TimeWorkedHours;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("M7:O7");
            cellA4 = worksheet.getCell("M7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Weighed;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

        }else if (data.section == 'header') {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            worksheet.mergeCells("D6:D7");
            worksheet.mergeCells("E6:E7");
            worksheet.mergeCells("F6:F7");
            worksheet.mergeCells("G6:G7");
            worksheet.mergeCells("H6:H7");
            worksheet.mergeCells("I6:I7");
            worksheet.mergeCells("J6:J7");
            worksheet.mergeCells("K6:K7");
            //Grupo 1
            worksheet.mergeCells("L7:N7");
            let cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '42aa91'
                }
            }            
            cellA4.value = data.FirstFullWeight;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("O7:Q7");
            cellA4 = worksheet.getCell("O7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.LastFullWeight;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("R7:T7");
            cellA4 = worksheet.getCell("R7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.MayorFullWeight;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("U6:U7");
            worksheet.mergeCells("V6:V7");
            worksheet.mergeCells("W6:W7");
            worksheet.mergeCells("X6:X7");
            worksheet.mergeCells("Y6:Y7");
            worksheet.mergeCells("Z6:Z7");
            worksheet.mergeCells("AA6:AA7");
            worksheet.mergeCells("AB6:AB7");
        }else {
            let dataRow = worksheet.addRow();
            let indice = 0;

            keyColumns.map(function (data2) {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
        }
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function getExcelInspection(rowData, titulo, excel, InspectionType, damageReport, companyName) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    const hoy = new Date();

    if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }
    
    keyColumns.splice(0, 2);
    columnsDefs.splice(0, 2);
    y = y-2;
        
    //if (isUndefined(un)) un = 'TON';
    if (excel) {

        let tituloExcel = "";
        if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
            tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
            this.dataBuque.CountryName = this.itinerarySelected.CountryName;
            this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        }

        if (y < 10) letra = 10;
        worksheet.mergeCells('A1:B2');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B2');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        if(companyName){
            worksheet.mergeCells("C1:" + numeroLetra(y) + "2")   
        }else if(companyName === undefined){ 
            worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
            worksheet.mergeCells("C2:" + numeroLetra(y) + "2");
        }

        const customCellCompanyBranch = worksheet.getCell("C1");

        const row1 = worksheet.getRow(1);
        row1.height = companyName ? 60 : 30;
        customCellCompanyBranch.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCellCompanyBranch.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCellCompanyBranch.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            }
        }
        customCellCompanyBranch.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
        customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            
        if(companyName){
        }else if(companyName === undefined){     
            const row2 = worksheet.getRow(2);    
            row2.height = 30;
            const customCell = worksheet.getCell("C2");
            customCell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCell.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell.value = tituloExcel;
            customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }

        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / 5);
        let rango1 = numeroLetra(division*2);
        let rango11 = numeroLetra((division * 2) +1);
        let rango2 = numeroLetra(division*3);
        let rango21 = numeroLetra((division * 3) +1);
        let rango3 = numeroLetra(division*4);
        let rango31 = numeroLetra((division * 4) +1);
        let rango4 = numeroLetra(y);

        //tipo de inspección
        worksheet.mergeCells("A4:" + rango1 +"4");
        const customCell3 = worksheet.getCell("A4");
        customCell3.height = 50;
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = this.$t('label.inspectionType')+': '+InspectionType;
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //tipo de dano
        worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
        const customCell4 = worksheet.getCell(rango11+"4");
        customCell4.height = 50;
        customCell4.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell4.value = this.$t('label.damageReport')+': '+damageReport;
        customCell4.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //fecha de impresion
        worksheet.mergeCells(rango21 + "4:" + rango3 + "4");
        const customCell6 = worksheet.getCell(rango21+"4");
        customCell6.height = 50;
        customCell6.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell6.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); //hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        customCell6.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //usuario
        worksheet.mergeCells(rango31 + "4:" + rango4 + "4");
        const customCell7 = worksheet.getCell(rango31+"4");
        customCell7.height = 50;
        customCell7.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        customCell7.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //linea
        worksheet.mergeCells("A5:Q5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
    headerRow.height = 30;
    
    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }
        
        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";
        
        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data; 
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })

    rowData.map(async function(data){
        if (data.Cont20FtDeck=="DECK") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:F7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }            
            cellA4.value = data.Cont20FtDeck;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("G7:J7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont20FtHold;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("K7:N7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont20FtTotal;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

        } else if (data.Cont20Full == "20F") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            //Grupo 1
            worksheet.mergeCells("D7:E7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:G7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("H7:I7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("J7:K7");
            cellA4 = worksheet.getCell("J7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 5
            worksheet.mergeCells("L7:M7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 6
            worksheet.mergeCells("N7:O7");
            cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {   
            for (let  i = 1; i <= 13; i++) { 
                worksheet.mergeCells(numeroLetra(i)+"6:"+numeroLetra(i)+"7");
            }  
            for (let  i = 26; i <= 28; i++) { 
                worksheet.mergeCells(numeroLetra(i)+"6:"+numeroLetra(i)+"7");
            }        
            //Grupo 1
            worksheet.mergeCells("N7:P7");
            let cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.CtaFunctionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("Q7:R7");
            cellA4 = worksheet.getCell("R7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.ComAddressIdentifier;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("S7:V7");
            cellA4 = worksheet.getCell("S7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.HanDescriptionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("W7:Y7");
            cellA4 = worksheet.getCell("W7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.TsrServicePriorityCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.section=="totalLoad") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:E7");
            let cellA4 = worksheet.getCell("C7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                },
            }            
            cellA4.value = "DECK";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:H7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = "HOLD";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }else {
            let dataRow = worksheet.addRow();
            let indice = 0;
            
            keyColumns.map(function (data2) {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
        }
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function getExcelArray(rowData, titulo, excel, companyName, Called) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;

    columnsDefs = Object.keys(rowData[0]);
    keyColumns = columnsDefs;
    y = columnsDefs.length;
    bandera = false;

    //if (isUndefined(un)) un = 'TON';
    if (excel) {
        let tituloExcel = "";
        if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
            tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
            this.dataBuque.CountryName = this.itinerarySelected.CountryName;
            this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        }

        if (y < 10) letra = 10;
        worksheet.mergeCells('A1:B1');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B2');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        if(companyName){
            worksheet.mergeCells("C1:" + numeroLetra(y) + "2")   
        }else if(companyName === undefined){ 
            worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
            worksheet.mergeCells("C2:" + numeroLetra(y) + "2");
        }

        const customCellCompanyBranch = worksheet.getCell("C1");

        const row1 = worksheet.getRow(1);
        row1.height = companyName ? 60 : 30;
        customCellCompanyBranch.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCellCompanyBranch.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCellCompanyBranch.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            }
        }
        customCellCompanyBranch.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
        customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

        if(companyName){
        }else if(companyName === undefined){     
            const row2 = worksheet.getRow(2);    
            row2.height = 40;
            const customCell = worksheet.getCell("C2");
            customCell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCell.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            if (Called) {
                customCell.value = tituloExcel+', '+this.$t('label.list_terminal.n_toque')+': '+Called;
            } else {
                customCell.value = tituloExcel;
            }
            customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }
//////


        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //columnas
        let division = Math.floor(y / 2);
        let rango1 = numeroLetra(division);
        let rango11 = numeroLetra(division+1);
        let rango2 = numeroLetra(y);
        
        const hoy = new Date();
        worksheet.mergeCells("A4:" + rango1 +"4");
        const customCell6 = worksheet.getCell("A4");
        customCell6.height = 50;
        customCell6.font = {
            name: "Calibri",
            family: 4,
            size: 11,
            underline: false,
            bold: true
        };

        customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
        customCell6.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy);//hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        customCell6.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //usuario
        worksheet.mergeCells(rango11+"4:" + rango2 +"4");
        const customCell7 = worksheet.getCell(rango11 + "4");
        customCell7.height = 50;
        customCell7.font = {
            name: "Calibri",
            family: 4,
            size: 11,
            underline: false,
            bold: true
        };

        customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
        customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        customCell7.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        }

        //linea
        worksheet.mergeCells("A5:Q5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    var headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
   /* headerRow.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
    } */
    headerRow.height = 30;

    let i = 0;
    columnsDefs.map(function (data) {
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
            long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
        let cell = headerRow.getCell(i + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'cccccc'
            }
        }
        cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        cell.value = bandera ? data.headerName : data.replace("_"," ");

        cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })

    rowData.map(async function (data) {
        if (data.Cont20FtDeck=="DECK") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:F7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }            
            cellA4.value = data.Cont20FtDeck;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("G7:J7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont20FtHold;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("K7:N7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont20FtTotal;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

        } else if (data.Cont20Full == "20F") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            //Grupo 1
            worksheet.mergeCells("D7:E7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:G7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("H7:I7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("J7:K7");
            cellA4 = worksheet.getCell("J7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 5
            worksheet.mergeCells("L7:M7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 6
            worksheet.mergeCells("N7:O7");
            cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {         
            for (let  i = 1; i <= 13; i++) { 
                worksheet.mergeCells(numeroLetra(i)+"6:"+numeroLetra(i)+"7");
            } 
            for (let  i = 26; i <= 28; i++) { 
                worksheet.mergeCells(numeroLetra(i)+"6:"+numeroLetra(i)+"7");
            }  
            //Grupo 1
            worksheet.mergeCells("N7:P7");
            let cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.CtaFunctionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("Q7:R7");
            cellA4 = worksheet.getCell("R7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.ComAddressIdentifier;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("S7:V7");
            cellA4 = worksheet.getCell("S7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.HanDescriptionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("W7:Y7");
            cellA4 = worksheet.getCell("W7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.TsrServicePriorityCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.section=="totalLoad") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:E7");
            let cellA4 = worksheet.getCell("C7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                },
            }            
            cellA4.value = "DECK";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:H7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = "HOLD";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }else {
            let dataRow = worksheet.addRow();
            let indice = 0;
            
            keyColumns.map(function (data2) {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
        }
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function getOperationalReport(Data, titulo, excel, companyName) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let currentDate = new Date();
    let date =DateFormater.formatDateTimeWithSlashToOnlyDate((currentDate));
    let rowData = Data.DetailsJson;
    let letra = 12;
    let columnsDefs = {
        Shift: this.$t('label.Shift'),
        HoldsJson: 'HoldsJson',
        TotalTonsLoaded: this.$t('label.TotalTonsLoaded'),
        Done: this.$t('label.done'),
        Remaing: this.$t('label.Remaing'),
        TonsAveragePerTruck: this.$t('label.TonsAveragePerTruck'),
    };

    //if (isUndefined(un)) un = 'TON';
        worksheet.mergeCells('A1:C1');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:C2');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        worksheet.mergeCells("D1:" + "M2");
        const title = worksheet.getCell("D1");

        const row1 = worksheet.getRow(1);
        row1.height = companyName ? 60 : 40;
        title.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        title.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        title.value = this.$t('label.operationalReport')+' / '+this.$t('label.BulkCarrierShips');
        title.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

        worksheet.getRow(2).height = 40;
        worksheet.mergeCells("N1:" +"O1");
        const customCell2 = worksheet.getCell("N1");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };

        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = this.$t('label.code')+': E-OCK-2021\n'+this.$t('label.review')+': 01'
        customCell2.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("N2:" +"O2");
        const customCell0 = worksheet.getCell("N2");
        customCell0.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };

        customCell0.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell0.value = this.$t('label.version')+': 01\n'+this.$t('label.FECHA')+': '+date;
        customCell0.border = {
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //Header
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + "C3");
        const customCell3 = worksheet.getCell("A3");
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = this.$t('label.VesselName');
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("D3:" + "H3");
        const customCell4 = worksheet.getCell("D3");
        customCell4.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell4.value = Data.HeaderJson[0].VesselName;
        customCell4.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("I3:" + "J3");
        const customCell5 = worksheet.getCell("I3");
        customCell5.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell5.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell5.value = this.$t('label.voyage');
        customCell5.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("K3:" + "O3");
        const customCell1 = worksheet.getCell("K3");
        customCell1.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell1.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell1.value = Data.HeaderJson[0].Voyage;
        customCell1.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.getRow(4).height = 30;
        worksheet.mergeCells("A4:" + "C4");
        const customCell6 = worksheet.getCell("A4");
        customCell6.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell6.value = this.$t('label.berth');
        customCell6.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("D4:" + "H4");
        const customCel7 = worksheet.getCell("D4");
        customCel7.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCel7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCel7.value = Data.HeaderJson[0].BerthName;
        customCel7.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("I4:" + "J4");
        const customCel8 = worksheet.getCell("I4");
        customCel8.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCel8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCel8.value = this.$t('label.DateOfBerthing');
        customCel8.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("K4:" + "O4");
        const customCel9 = worksheet.getCell("K4");
        customCel9.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCel9.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCel9.value = DateFormater.formatDateTimeWithSlash(Data.HeaderJson[0].DateOfBerthing);
        customCel9.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.getRow(5).height = 30;
        worksheet.mergeCells("A5:" + "B5");
        const customCell10 = worksheet.getCell("A5");
        customCell10.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell10.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell10.value = this.$t('label.client');
        customCell10.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("C5:" + "D5");
        const customCell11 = worksheet.getCell("C5");
        customCell11.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell11.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell11.value = Data.HeaderJson[0].ClientName;
        customCell11.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("E5:" + "F5");
        const customCell12 = worksheet.getCell("E5");
        customCell12.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell12.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell12.value = 'RR HH';
        customCell12.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("G5:" + "H5");
        const customCell13 = worksheet.getCell("G5");
        customCell13.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell13.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell13.value = Data.HeaderJson[0].RrHhName;
        customCell13.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("I5:" + "J5");
        const customCell14 = worksheet.getCell("I5");
        customCell14.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell14.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell14.value = this.$t('label.dateStartOperations');
        customCell14.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("K5:" + "O5");
        const customCell15 = worksheet.getCell("K5");
        customCell15.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell15.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell15.value = Data.HeaderJson[0].StartOperations ? DateFormater.formatDateTimeWithSlash(Data.HeaderJson[0].StartOperations) : '';
        customCell15.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        worksheet.getRow(6).numFmt = '#,##0.00;[Red]\-#,##0.00';
        worksheet.getRow(6).height = 25;
        worksheet.getRow(7).height = 25;
        worksheet.mergeCells("A6:" + "B7");
        const customCell16 = worksheet.getCell("A6");
        customCell16.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell16.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell16.value = this.$t('label.ServiceRequest')+' (SSP)';
        customCell16.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("C6:" + "D7");
        const customCell17 = worksheet.getCell("C6");
        customCell17.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell17.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell17.value = Data.HeaderJson[0].SSP;
        customCell17.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        
        worksheet.mergeCells("E6:" + "F7");
        const customCell18 = worksheet.getCell("E6");
        customCell18.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell18.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell18.value = this.$t('label.stowagePlan');
        customCell18.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("G6:" + "H7");
        const customCell19 = worksheet.getCell("G6");
        customCell19.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell19.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell19.value = Data.HeaderJson[0].StowePlan;
        customCell19.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("I6:" + "I7");
        const customCell20 = worksheet.getCell("I6");
        customCell20.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell20.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell20.value = this.$t('label.TotalTonsLoaded');
        customCell20.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("J6:" + "J7");
        const customCell21 = worksheet.getCell("J6");
        customCell21.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell21.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell21.value = Data.HeaderJson[0].TotalLoadedTon;
        customCell21.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("K6:" + "M6");
        const customCell22 = worksheet.getCell("K6");
        customCell22.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell22.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell22.value = 'SSP / '+this.$t('label.Loaded');
        customCell22.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("K7:" + "M7");
        const customCell23 = worksheet.getCell("K7");
        customCell23.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell23.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell23.value = Data.HeaderJson[0].SSP_Loaded+'%';
        customCell23.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("N6:" + "O6");
        const customCell24 = worksheet.getCell("N6");
        customCell24.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell24.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell24.value = this.$t('label.stowagePlan')+' / '+this.$t('label.Loaded');
        customCell24.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("N7:" + "O7");
        const customCell25 = worksheet.getCell("N7");
        customCell25.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell25.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell25.value = Data.HeaderJson[0].StowePlan_Loaded+'%';
        customCell25.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.getRow(8).height = 30;
        worksheet.mergeCells("A8:" + "C8");
        const customCell26 = worksheet.getCell("A8");
        customCell26.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell26.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell26.value = this.$t('label.totalQuantity');
        customCell26.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        worksheet.mergeCells("D8:" + "J8");
        const customCell27 = worksheet.getCell("D8");
        customCell27.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell27.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell27.value = Data.HeaderJson[0].TotalQuantity;
        worksheet.getRow(8).numFmt = '#,##0.00;[Red]\-#,##0.00';
        customCell27.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
        }

        worksheet.mergeCells("K8:" + "O8");
        const customCell28 = worksheet.getCell("K8");
        customCell28.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: false
        };
        customCell28.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell28.value = this.$t('label.MetalScrapTon');
        customCell28.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        }
        worksheet.getColumn('B').width = 20;
        worksheet.getColumn('I').width = 20;
        worksheet.getColumn('J').width = 15;
        worksheet.getColumn('O').width = 25;
    var headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    headerRow.height = 30;

    worksheet.mergeCells("A9:B9");
    const TableHeader1 = headerRow.getCell(1);
    TableHeader1.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'cccccc'
        }
    }
    TableHeader1.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    TableHeader1.value = columnsDefs.Shift;
    TableHeader1.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' };

    let Extra = (6-(rowData[0].HoldsJson.length));
    if (Extra == 1) {
        worksheet.mergeCells("F9:G9");
    }else if (Extra == 2) {
        worksheet.mergeCells("D9:E9");
        worksheet.mergeCells("F9:G9");
    }else if (Extra == 3) {
        worksheet.mergeCells("C9:D9");
        worksheet.mergeCells("E9:G9");
    }else if (Extra == 4) {
        worksheet.mergeCells("C9:G9");
    }
    let i = 2;
    for (let index = 0; index < rowData[0].HoldsJson.length; index++) {
        let valor = rowData[0].HoldsJson[index].VesselHoldName.length;
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
            long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        worksheet.getColumn(i + 1).width = long;
        let cell = headerRow.getCell(i + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'cccccc'
            }
        }
        cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        cell.value = rowData[0].HoldsJson[index].VesselHoldName;
        cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
        if (Extra == 1 && index == 3) {
            i = 5;
        }else if (Extra == 2 && index == 1) {
            i = 3;
        }else if(Extra == 2 && index == 3){
            i = 5;
        } else if (Extra == 3 && index == 0) {
            i = 2;
        }else if (Extra == 3 && index ==2) {
            i = 5;
        }else{
            i++
        }
    }

    const TableHeader8 = headerRow.getCell(9);
    TableHeader8.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'cccccc'
        }
    }
    TableHeader8.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    TableHeader8.value = columnsDefs.TotalTonsLoaded;
    TableHeader8.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells("J9:K9");
    const TableHeader9 = headerRow.getCell(10);
    TableHeader9.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'cccccc'
        }
    }
    TableHeader9.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    TableHeader9.value = columnsDefs.Done;
    TableHeader9.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells("L9:M9");
    const TableHeader10 = headerRow.getCell(12);
    TableHeader10.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'cccccc'
        }
    }
    TableHeader10.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    TableHeader10.value = columnsDefs.Remaing;
    TableHeader10.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells("N9:O9");
    const TableHeader11 = headerRow.getCell(14);
    TableHeader11.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'cccccc'
        }
    }
    TableHeader11.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    TableHeader11.value = columnsDefs.TonsAveragePerTruck;
    TableHeader11.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }

    let Row = 10;
    let TotalHol1 = 0;
    let TotalHol2 = 0;
    let TotalHol3 = 0;
    let TotalHol4 = 0;
    let TotalHol5 = 0;
    let TotalTrucks = 0;
    rowData.map(async function(data){
        let dataRow = worksheet.addRow();
        worksheet.mergeCells("A"+Row+":"+"B"+Row);
        let cell1 = dataRow.getCell(1);
        cell1.value = data.Shift;
        cell1.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
        cell1.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

        if (Extra == 1) {
            worksheet.mergeCells("F"+Row+":"+"G"+Row);
        }else if (Extra == 2) {
            worksheet.mergeCells("D"+Row+":"+"E"+Row);
            worksheet.mergeCells("F"+Row+":"+"G"+Row);
        }else if (Extra == 3) {
            worksheet.mergeCells("C"+Row+":"+"D"+Row);
            worksheet.mergeCells("D"+Row+":"+"G"+Row);
        }else if (Extra == 4) {
            worksheet.mergeCells("C"+Row+":"+"G"+Row);
        }
        i = 2;
        for (let index = 0; index < data.HoldsJson.length; index++) {
            TotalHol1 = index == 0 ? TotalHol1+data.HoldsJson[index].CantTrucks : TotalHol1;
            TotalHol2 = index == 1 ? TotalHol2+data.HoldsJson[index].CantTrucks : TotalHol2;
            TotalHol3 = index == 2 ? TotalHol3+data.HoldsJson[index].CantTrucks : TotalHol3;
            TotalHol4 = index == 3 ? TotalHol4+data.HoldsJson[index].CantTrucks : TotalHol4;
            TotalHol5 = index == 4 ? TotalHol5+data.HoldsJson[index].CantTrucks : TotalHol5;
            TotalTrucks = index == 5 ? TotalTrucks+data.HoldsJson[index].CantTrucks : TotalTrucks;
            let HoldsJson = dataRow.getCell(i + 1);
            HoldsJson.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
            HoldsJson.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            HoldsJson.value = data.HoldsJson[index].CantTrucks;
    
            if (Extra == 1 && index == 3) {
                i = 5;
            }else if (Extra == 2 && index == 1) {
                i = 3;
            }else if(Extra == 2 && index == 3){
                i = 5;
            } else if (Extra == 3 && index == 0) {
                i = 2;
            }else if (Extra == 3 && index ==2) {
                i = 5;
            }else{
                i++
            }
        }

        let cell2 = dataRow.getCell(9);
        cell2.value = data.TotalLoaded;
        cell2.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
        cell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

        worksheet.mergeCells("J"+Row+":"+"K"+Row);
        let cell3 = dataRow.getCell(10);
        cell3.value = data.Done;
        cell3.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
        cell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

        worksheet.mergeCells("L"+Row+":"+"M"+Row);
        let cell4 = dataRow.getCell(12);
        cell4.value = data.Remain;
        cell4.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell4.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
        cell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

        worksheet.mergeCells("N"+Row+":"+"O"+Row);
        let cell5 = dataRow.getCell(14);
        cell5.value = data.AveragePerTruckInTons;
        cell5.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell5.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
        cell5.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

        Row++;
    })
    
    let dataRow = worksheet.addRow();
    if (Extra == 1) {
        worksheet.mergeCells("F"+Row+":"+"G"+Row);
    }else if (Extra == 2) {
        worksheet.mergeCells("D"+Row+":"+"E"+Row);
        worksheet.mergeCells("F"+Row+":"+"G"+Row);
    }else if (Extra == 3) {
        worksheet.mergeCells("C"+Row+":"+"D"+Row);
        worksheet.mergeCells("D"+Row+":"+"G"+Row);
    }else if (Extra == 4) {
        worksheet.mergeCells("C"+Row+":"+"G"+Row);
    }
    i = 2;
    for (let index = 0; index < rowData[0].HoldsJson.length; index++) {

        let HoldsJson = dataRow.getCell(i + 1);
        HoldsJson.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
        HoldsJson.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        if(index==0){
            HoldsJson.value = TotalHol1;
        }else if(index==1){
            HoldsJson.value = TotalHol2;
        }else if(index==2){
            HoldsJson.value = TotalHol3;
        }else if(index==3){
            HoldsJson.value = TotalHol4;
        }else if(index==4){
            HoldsJson.value = TotalHol5;
        }else{
            HoldsJson.value = TotalTrucks;
        }

        if (Extra == 1 && index == 3) {
            i = 5;
        }else if (Extra == 2 && index == 1) {
            i = 3;
        }else if(Extra == 2 && index == 3){
            i = 5;
        } else if (Extra == 3 && index == 0) {
            i = 2;
        }else if (Extra == 3 && index ==2) {
            i = 5;
        }else{
            i++
        }
    }

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    workbook.xlsx.writeBuffer()
        .then(buffer => {
            saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
            this.$notify({
                group: 'container',
                text: this.$t('label.reportSuccessfully'),
                type: "success"
            });
        })
}

async function getTimeReport(rowData, titulo, excel, CraneName, fromDay, toDay, movement) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    const hoy = new Date();

    if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }
        
    //if (isUndefined(un)) un = 'TON';
    if (excel) {

        let tituloExcel = "";
        if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
            tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
            this.dataBuque.CountryName = this.itinerarySelected.CountryName;
            this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        }

        if (y < 10) letra = 10;
        worksheet.mergeCells('A1:B1');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B1');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        worksheet.mergeCells("C1:" + numeroLetra(y) +"1");
        
        const customCell = worksheet.getCell("C1");
        const row = worksheet.getRow(1);
        row.height = 60;
        customCell.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell.note = {
            margins : {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCell.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{
                argb:'f2f2f2' 
            }
        }
        customCell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        
        customCell.value = tituloExcel;
        customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

        //titulo
        worksheet.getRow(2).height = 30;
        worksheet.mergeCells("A2:" + numeroLetra(y) +"2");
        const customCell2 = worksheet.getCell("A2");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / 5);
        let rango1 = numeroLetra(division*2);
        let rango11 = numeroLetra((division * 2) +1);
        let rango2 = numeroLetra(division*3);
        let rango21 = numeroLetra((division * 3) +1);
        let rango3 = numeroLetra(division*4);
        let rango31 = numeroLetra((division * 4) +1);
        let rango4 = numeroLetra(y);

        //rango de fechas
        worksheet.mergeCells("A3:" + rango1 +"3");
        const customCell3 = worksheet.getCell("A3");
        customCell3.height = 50;
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = this.$t('label.from')+': '+fromDay+' - '+this.$t('label.to')+': '+toDay;
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //grua
        worksheet.mergeCells(rango11 + "3:" + rango2 + "3");
        const customCell5 = worksheet.getCell(rango11+"3");
        customCell5.height = 50;
        customCell5.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell5.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell5.value = this.$t('label.crane')+': '+CraneName;
        customCell5.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //fecha de impresion
        worksheet.mergeCells(rango21 + "3:" + rango3 + "3");
        const customCell6 = worksheet.getCell(rango21+"3");
        customCell6.height = 50;
        customCell6.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell6.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); 
       
        customCell6.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //usuario
        worksheet.mergeCells(rango31 + "3:" + rango4 + "3");
        const customCell7 = worksheet.getCell(rango31+"3");
        customCell7.height = 50;
        customCell7.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        customCell7.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //linea
        worksheet.mergeCells("A4:Q4");
        const customCell8 = worksheet.getCell("A4");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        if(movement){
            //CONTAINER
            worksheet.mergeCells("A5:H5");
            worksheet.mergeCells("N5:S5");
            const customCell9 = worksheet.getCell("N5");
            customCell9.height = 50;
            customCell9.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            customCell9.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{
                    argb:'cccccc'
                }
            }
            customCell9.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell9.value = this.$t('label.containers');
            customCell9.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };

    headerRow.height = 30;
    
    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }
        
        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";
        
        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data; 
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })


    rowData.map(async function(data){
        let dataRow = worksheet.addRow();
        let indice = 0;
        keyColumns.map(function(data2, index2){
            let cell = dataRow.getCell(indice + 1);
            if(typeof data[data2] === 'boolean'){
                cell.value = data[data2] ? 'YES' : 'NO';
            }else{
                cell.value = data[data2];
            }
            if (indice!=0 && !isNaN(data[data2])) {
                cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            indice++;
        })
    })

    if(movement){
        let inicio=6;
        let final=7;
        worksheet.mergeCells("A"+inicio+":A"+final); 
        worksheet.mergeCells("B"+inicio+":B"+final); 
        worksheet.mergeCells("C"+inicio+":C"+final); 
        worksheet.mergeCells("D"+inicio+":D"+final); 
        worksheet.mergeCells("E"+inicio+":E"+final); 
        worksheet.mergeCells("F"+inicio+":F"+final);
        worksheet.mergeCells("G"+inicio+":G"+final);
        worksheet.mergeCells("H"+inicio+":H"+final);
        worksheet.mergeCells("I"+inicio+":I"+final);

        worksheet.mergeCells("J"+inicio+":J"+final);
        worksheet.mergeCells("K"+inicio+":K"+final);
        worksheet.mergeCells("L"+inicio+":L"+final);
        worksheet.mergeCells("M"+inicio+":M"+final);
        worksheet.mergeCells("T"+inicio+":T"+final);
        worksheet.mergeCells("N"+final+":P"+final);
        worksheet.mergeCells("Q"+final+":S"+final);

        worksheet.getCell("N"+final).value = "FULL";
        worksheet.getCell("Q"+final).value = "EMPTY";

        worksheet.getCell("N"+final).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'cccccc'
            }
        }
        worksheet.getCell("N"+final).font = {
            name: "Calibri",
            family: 4,
            size: 11,
            underline: false,
            bold: true
        };
        worksheet.getCell("Q"+final).font = {
            name: "Calibri",
            family: 4,
            size: 11,
            underline: false,
            bold: true
        };
        worksheet.getCell("Q"+final).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'cccccc'
            }
        }
    }

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function getDamageReport(item, type, correlative, InspectionTransactionDate, inspectionData ) {
    const documento = "REPORT OF DAMAGE";
    let urlLeft = 'download/DamageReportDoor.png'
    let urlRight = 'download/DamageReportTransversal.png'
    //logo
    const getImgLogo = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const extensionImgLogo = this.user.LogoRoute.split(".");
    const myBase64ImageLogo = 'data:image/'+extensionImgLogo[1]+';base64,'+getImgLogo.data.data.base64;

    //imagen left
    const getImgLeft = await this.$http.getFile('base64', {
        path:urlLeft.replace('Public/','', null, 'i')
    });
    const extensionImgLeft = urlLeft.split(".");
    const myBase64ImageLeft = 'data:image/'+extensionImgLeft[1]+';base64,'+getImgLeft.data.data.base64;


    //imagen right
    const getImgRight = await this.$http.getFile('base64', {
        path:urlRight.replace('Public/','', null, 'i')
    });
    const extensionImgRight = urlRight.split(".");
    const myBase64ImageRight = 'data:image/'+extensionImgRight[1]+';base64,'+getImgRight.data.data.base64;

    let resta = 0;
    let tcentro = 300;
    let ancho = 535;
    let anchoReal = 595;
    let font = 8;
    let largo = 842;
    const doc = new jsPDF({
        orientation: 'p',
        unit: 'pt',
        format: [anchoReal, largo],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });
    const hoy = new Date();
    let fecha = DateFormater.formatTimeZoneToDateTime(hoy);
    let arrDate = DateFormater.formatDateTimeWithSlashToOnlyDate(fecha).split("/");
    let year = arrDate[2], month = arrDate[1], day = arrDate[0];

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        
        doc.setFontSize(8);
        doc.setTextColor(0);
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed') + ": " + fecha, 30, largo-40, {
                align: 'left'
            });
            doc.text(this.$t('label.user') + ": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 30, largo-40, {
                align: 'right'
            });
            doc.text(this.$t('label.pageReport') + " "+ String(i) + this.$t('label.ofReport') + " "+ String(pageCount), doc.internal.pageSize.width -30, largo-25, {
                align: 'right'
            })
        }
    }

    if(!item.FgActInspectionMaster){
        doc.setFontSize(80);
        doc.setTextColor("#c1c5c3");
        doc.text(this.$t('label.canceledDocument'), 150, 390, null, 25);
        doc.text(this.$t('label.canceledDocument'), 150, 690, null, 25);
    }
    doc.setTextColor(0);

    //LINEA 1------------------------------------------------------------------------------
    //CUADRO 1 imagen
    doc.setDrawColor("#000");
    doc.setFontSize(9);
    doc.rect(30, 40, 155, 80);
    doc.addImage(myBase64ImageLogo, "JPEG", 33, 43, 149, 73);
    doc.rect(185, 40, 240, 80)
    doc.rect(395, 50, 20, 15)
    doc.text("DAMAGE REPORTS/ Reporte  de daños", tcentro-25, 60, 'center');
    doc.rect(395, 85, 20, 15)
    if(type) doc.text("X", 405, 60, 'center');

    //CUADRO 2 tipo
    doc.text("SEAL DISCREPANCE/ Discrepancia de Precinto", tcentro-10, 96, 'center');
    doc.rect(425, 40, 140, 40)
    if(!type) doc.text("X", 405, 96, 'center');

    //CUADRO fecha
    doc.rect(425, 40, 40, 23);
    doc.rect(425, 63, 40, 17);
    doc.text("DAY /", 447, 49, 'center');
    doc.text("Dia:", 448, 59, 'center');
    doc.text(day, tcentro+146, 74, 'center');

    doc.rect(465, 40, 50, 23);
    doc.rect(465, 63, 50, 17);
    doc.text("MONTH /", tcentro+191, 49, 'center');
    doc.text("Mes:", 490, 59, 'center');
    doc.text(month, tcentro+189, 74, 'center');
        
    doc.rect(515, 40, 50, 23);
    doc.rect(515, 63, 50, 17);

    doc.text("YEAR /", tcentro+240, 49, 'center');
    doc.text("Year:", 540, 59, 'center');
    doc.text(year, tcentro+240, 74, 'center');
   
    doc.setFontSize(8);
    doc.rect(425, 40, 140, 80)
    doc.text("NRO-OCKVRP-2022-", tcentro+190, 100, 'center');
    doc.text(correlative, tcentro+190, 113, 'center');

    //LINEA 2------------------------------------------------------------------------------
    doc.setFontSize(9);
    doc.setFillColor("#FCD5B4");
    doc.rect(30, 120, ancho, 20,"FD");
    doc.text("REPORT OF DAMAGE IN CONTAINERS AND SEALED DISCREPANCIES", tcentro, 133, 'center');

    //LINEA 3------------------------------------------------------------------------------
    doc.setFontSize(7.5);
    doc.setFillColor("#CCC");
    doc.rect(30, 140, ancho/5, 30);
    doc.text("VESSEL/ Buque:", 33, 150, 'left');
    doc.text(item.VesselNameReport, 33, 162, 'left');

    doc.rect(30+(ancho/5), 140, ancho/5, 30);
    doc.text("VOYAGE/ Viaje:", 33+(ancho/5), 150, 'left');
    doc.text(item.Voyage, 33+(ancho/5), 162, 'left');

    doc.rect(30+((ancho/5)*2), 140, (ancho/5)-30, 30);
    doc.text("PIER N°/ N° Muelle:", 33+((ancho/5)*2), 150, 'left');
    doc.text(item.BerthName, 33+((ancho/5)*2), 162, 'left');


    doc.rect(((ancho/5)*3), 140, ancho/5, 30);
    doc.text("SLOT/BAY/ Posición/Bay:", ((ancho/5)*3)+3, 150, 'left');
    doc.text(item.BaySlot, ((ancho/5)*3)+3, 162, 'left'); // ************bay


    doc.rect(((ancho/5)*4), 140, (ancho/5)+30, 30);
    doc.text("DATE HOUR AT OPRTS/ Fecha-Hora", ((ancho/5)*4)+3, 150, 'left');
    doc.text("de las op.", ((ancho/5)*4)+3, 162, 'left');
    doc.text(InspectionTransactionDate, ((ancho/5)*4)+40, 162, 'left');
    //LINEA 4------------------------------------------------------------------------------
    doc.rect(30, 170, (ancho/4)-10, 30);
    doc.text("DISCH PORT/ Puerto de descarga:", 33, 180, 'left');
    doc.text(item.DischargePort, 33, 192, 'left')


    doc.rect(20+(ancho/4), 170, (ancho/4)-10, 30);
    doc.text("LOAD PORT/ Puerto de embarque:", 23+(ancho/4), 180, 'left');
    doc.text(item.LoadPort, 23+(ancho/4), 192, 'left');

    doc.rect(10+((ancho/4)*2), 170, (ancho/4), 30);
    doc.text("BL N°:", 13+((ancho/4)*2), 180, 'left');
    doc.text(item.BlNo, 13+((ancho/4)*2), 192, 'left');

    doc.rect(10+((ancho/4)*3), 170, (ancho/4)+20, 30);
    doc.text("CONTAINER NUMBER/Nro de contenedor:", 13+((ancho/4)*3), 180, 'left');
    doc.text(item.ContainerCode, 13+((ancho/4)*3), 192, 'left');

    //LINEA 5------------------------------------------------------------------------------
    doc.rect(30, 200, (ancho/4)-10, 30);
    doc.text("SIZE / Tamaño", 33, 210, 'left');

    doc.text("20'", 33, 220, 'left');
    doc.rect((ancho/5)-60, 213, 10, 10)
    if(item.Size === '20') 
        doc.text("X",  (ancho/5)-57, 220, 'left');

    doc.text("40'", (ancho/5)-40, 220, 'left');
    doc.rect((ancho/5)-25, 213, 10, 10)
    if(item.Size === '40') 
        doc.text("X", (ancho/5)-23, 220, 'left');

    doc.text("__", (ancho/5), 220, 'left');
    doc.rect((ancho/5)+10, 213, 10, 10)
    if(item.Size === '45') 
        doc.text("X", (ancho/5)+12, 220, 'left');

    doc.rect(20+(ancho/4), 200, (ancho/4)-10, 30);
    doc.text("CONDITION / Condición:", 23+(ancho/4), 210, 'left');

    doc.text("Full", 23+(ancho/4), 220, 'left');
    doc.rect(43+(ancho/4), 213, 10, 10)
    if(item.Estatus === 'FULL')  
    doc.text("X", 45+(ancho/4), 220, 'left');

    doc.text("Mty", 63+(ancho/4), 220, 'left');
    doc.rect(83+(ancho/4), 213, 10, 10)
    if(item.Estatus !== 'FULL') 
    doc.text("X", 85+(ancho/4), 220, 'left');

    doc.rect(10+((ancho/4)*2), 200, 2*(ancho/4)+20, 30);
    doc.text("TYPE/ Tipo:", 13+((ancho/4)*2), 210, 'left');
    doc.text("ST__   FT__   HC__   OT__   PL__   RT__   RH__   TK__", 13+((ancho/4)*2), 220, 'left');
    if(item.TpCargoId === '1C76345A-8DCC-4CDC-B4F0-1FE85A73916C') doc.text("X", ((ancho/4)*2)+26,  220, 'left');
    if(item.TpCargoId === '9E152C00-1F6A-47AD-85F0-5D9EC5EDE5AB') doc.text("X", ((ancho/4)*2)+51,  220, 'left');
    if(item.TpCargoId === '6C6CE673-3797-4D11-839B-06C228D51CEF') doc.text("X", ((ancho/4)*2)+76,  220, 'left');
    if(item.TpCargoId === '53F364E6-E4BA-4356-B1CE-B7BBF87B612D') doc.text("X", ((ancho/4)*2)+100, 220, 'left');
    if(item.TpCargoId === '51FD9A2F-F692-4665-95EA-DEE0378462C1') doc.text("X", ((ancho/4)*2)+125, 220, 'left');
    if(item.TpCargoId === '40EC1BCA-4430-4CA0-8856-B0CD38AB7CA5') doc.text("X", ((ancho/4)*2)+149, 220, 'left');
    if(item.TpCargoId === '7086036D-93B3-42D6-BB28-8071707B9C80') doc.text("X", ((ancho/4)*2)+175, 220, 'left');
    if(item.TpCargoId === '3D8A2E52-A132-4379-A769-7950F0B9A967') doc.text("X", ((ancho/4)*2)+199, 220, 'left');

    //LINEA 6------------------------------------------------------------------------------
    doc.rect(30, 230, ancho, 28);
    doc.rect(30, 230, (ancho/4)+68, 28);
    doc.text("MOMENT OF THE OPERATION/Momento de operación:", 33, 240, 'left');

    doc.text("Before/Antes", 33, 250, 'left');
    doc.rect(77, 243, 10, 10)
    if(item.InspectionMoment === 'DB9DAED6-E6C2-4DF0-BA2A-55BDDECA6C76') doc.text("X", 80, 250, 'left');

    doc.text("During/Durante", 88, 250, 'left');
    doc.rect(140, 243, 10, 10)
    if(item.InspectionMoment === '58824ECD-A6AD-4450-9027-48E08E5CE05B') doc.text("X", 143, 250, 'left');

    doc.text("Other/ Otro", 17+(ancho/4), 250, 'left');
    doc.rect(55+(ancho/4), 243, 10, 10)
    if(item.InspectionMoment === '88B3BBC5-80F6-4B3A-8A3A-9763148E89C4') doc.text("X", 58+(ancho/4), 250, 'left');
    
    //********tipo de operacion */
    doc.text("TYPE OPERATION/ Tipo de operación:", 100+(ancho/4), 240, 'left');
    doc.text("Discharge/Descarga", 100+(ancho/4), 250, 'left');
    doc.rect(148+(ancho/4), 263, 10, 10)
    if(item.MovStowage === 'A13A4AE4-CDAA-4BB1-8CE6-00056BED3A8B') doc.text("X", 171+(ancho/4), 250, 'left');

    doc.text("Load/Carga", 185+(ancho/4), 250, 'left');
    doc.rect(225+(ancho/4), 243, 10, 10)
    if(item.MovStowage === '8F8EBDB2-0AD1-4ADB-B74A-E4E7E10F469B') doc.text("X", 228+(ancho/4), 250, 'left');

    doc.text("Ship Land To Ship/Buque Tierra Buque", 250+(ancho/4), 250, 'left');
    doc.rect(383+(ancho/4), 243, 10, 10)
    if(item.TransacStowage === 'B36EE884-F5CE-414F-81EA-DCC6933008A0') doc.text("X", 386+(ancho/4), 250, 'left');

    doc.text("Ship To Ship/Buque a Buque", 250+(ancho/4), 240, 'left');
    doc.rect(350+(ancho/4), 232, 10, 10)
    if(item.TransacStowage === 'A1B97999-F4A4-4B0D-9060-53B8AF9A4F4D') doc.text("X", 353+(ancho/4), 240, 'left');

    //LINEA 7-----------------------------------------------------------------------------
    doc.rect(30, 258, (ancho/4), 64);
    doc.text("ORIGINAL SEAL/ Precinto original:", 33, 268, 'left');

   doc.rect(30+(ancho/4), 258, (ancho/4)+1, 64);
   doc.text("NEW SEAL/ Nuevo precinto:", 33+(ancho/4), 268, 'left');
 
   doc.rect(31+((ancho/4)*2), 258, (ancho/4), 64);
   doc.text("SEAL CONDITION/ Condición del \nprecinto:", 34+((ancho/4)*2), 268, 'left');

   doc.rect(31+((ancho/4)*3), 258, (ancho/4)-1, 64);
   doc.text("SEAL REMARKS/ Observación \ndel precinto:", 34+((ancho/4)*3), 268, 'left');

    /////////// seals details////////
    if(!type){
        let columns = [];
        let columnDefs = Object.keys(inspectionData[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                 dataKey: columnDefs[index],
            });
        }

        let tableWidthBody = 500;

        let inicio = 279;
        doc.autoTable(columns, inspectionData, {
            startY: inicio,
            theme: 'plain',
            columnWidth: 70,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 38 },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: font, 
                halign: "left", 
                valign: "middle",
                cellWidth: 132,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
           /* columnStyles: {
                overflow: 'linebreak',
            }, */
        });
    }
    doc.setDrawColor("#000");
    //LINEA 8------------------------------------------------------------------------------
    doc.rect(30, 322, ancho, 18);
    doc.text("DAMAGE CODE / CODIGO DE DAÑOS", tcentro, 333, 'center');
    //LINEA 9------------------------------------------------------------------------------
    doc.rect(30, 340, (ancho/8), 30);
    doc.text("C:\n CUT/Corte", 65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*2)+5, 30);
    doc.text("PH:\n Pin Hole/Agujereado", (ancho/8)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*3), 30);
    doc.text("M: Missing/\n Desaparecido", ((ancho/8)*2)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*4), 30);
    doc.text("CI: Caved in/\n Hundido en", ((ancho/8)*3)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*5), 30);
    doc.text("RU: \n Rusted/Oxidado", ((ancho/8)*4)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*6), 30);
    doc.text("R: \n Ripped/Rasgado", ((ancho/8)*5)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*7)+5, 30);
    doc.text("BO: \n Bulgedout/Abultado", ((ancho/8)*6)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*8), 30);
    doc.text("PA: Patched/\n Parcheado", ((ancho/8)*7)+65, 352, 'center');
    //LINEA 10------------------------------------------------------------------------------
    doc.rect(30, 370, (ancho/8), 30);
    doc.text("BR:\n Broken/Roto", 65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*2)+5, 30);
    doc.text("D:\n Dented/Abollado", (ancho/8)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*3), 30);
    doc.text("BO: Bad odor/\n Mal olor", ((ancho/8)*2)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*4), 30);
    doc.text("DI: Distorted\n Distorsionado", ((ancho/8)*3)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*5), 30);
    doc.text("BE: \n Bent/Doblado", ((ancho/8)*4)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*6), 30);
    doc.text("L: \n Leak/Filtración", ((ancho/8)*5)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*7)+5, 30);
    doc.text("H: \n Holed/Agujereado", ((ancho/8)*6)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*8), 30);
    doc.text("O: Others/\n Otros", ((ancho/8)*7)+65, 382, 'center');
    //LINEA 11------------------------------------------------------------------------------
    doc.rect(30, 400, ancho, 200);
    doc.text("INDICATE THE DAMAGE AREA OF THE CONTAINER/ Indique la zona de daños del contenedor:", 33, 415, 'left');
    doc.addImage(myBase64ImageLeft, "JPEG", 33, 415, ancho/2+1, 180);
    doc.addImage(myBase64ImageRight, "JPEG", 31+((ancho/4)*2), 415, ancho/2-3, 180);
    doc.text("See overleaf for codes", tcentro, 595, 'center');
    //LINEA 12------------------------------------------------------------------------------
    doc.rect(30, 600, ancho, 15);
    doc.text("Brief description of damage / Breve descripción de daños", tcentro, 608, 'center');

    doc.rect(30, 615, ancho/5, 135);
    doc.text("Damage / Daño", 33, 625, 'left');

    doc.rect(30+ancho/5, 615, ancho/5-10, 135);
    doc.text("Location / Localización:", 33+ancho/5, 625, 'left');
  
    doc.rect(20+ancho/5*2, 615, ancho/5, 135);
    doc.text("Dimensions / Dimensiones:", 24+ancho/5*2, 625, 'left');

    doc.rect(20+ancho/5*3, 615, ancho/5, 135);
    doc.text("Responsible / Responsable:", 24+ancho/5*3, 625, 'left');

    doc.rect(20+ancho/5*4, 615, (ancho/5)+10, 135);
    doc.text("Observation / Observación", 24+ancho/5*4, 625, 'left');

    /////////// damage details////////
    if(type){
        let columns = [];
        let columnDefs = Object.keys(inspectionData[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 500;

        let inicio = 630;
        doc.autoTable(columns, inspectionData, {
            startY: inicio,
            theme: 'plain',
            columnWidth: ancho/5,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 38 },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: font, 
                halign: "left", 
                valign: "middle",
                cellWidth: ancho/5,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
            columnStyles: {
                overflow: 'linebreak',
                1: { halign: 'left', cellWidth: ancho/5-10, }
            },
        });
    }

    //LINEA 15------------------------------------------------------------------------------
    doc.setFontSize(7.5);
    doc.setDrawColor("#000");
    doc.rect(30, 750, (ancho/3), 40);
    doc.rect(30, 750, (ancho/3)*2, 40);
    doc.rect(30, 750, (ancho/3)*3, 40);
    
    ///////////OperatorName start///////////
    if(item.OperatorName != ''){
        let dataInfo = [];
        let tablaY = 752;
        let textoY = 757;
        dataInfo.push({ name: "Operator - Dispatcher / Operador - Despachador:" });
        dataInfo.push({ name: item.OperatorName });
        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                 dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = ancho/3 -5;

        doc.autoTable(columns, dataInfo, {
            startY: tablaY,
            theme: 'plain',
            columnWidth: textoY,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 32 },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: font, 
                halign: "left", 
                valign: "middle",
                cellWidth: tableWidthBody,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
        });
    }
    doc.setDrawColor("#000");
    ///////////OperatorName end///////////

    ///////////SupervisorName start///////////
    if(item.SupervisorName != ''){
        let dataInfo = [];
        let tablaY = 752;
        let textoY = 757;
        dataInfo.push({ name: this.user.CompanyName+" Supervisor:" });
        dataInfo.push({ name: item.SupervisorName });
        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                 dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = ancho/3 -5;

        doc.autoTable(columns, dataInfo, {
            startY: tablaY,
            theme: 'plain',
            columnWidth: textoY,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 32+ancho/3 },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: font, 
                halign: "left", 
                valign: "middle",
                cellWidth: tableWidthBody,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
        });
    }
    doc.setDrawColor("#000");
    ///////////SupervisorName end///////////

    ///////////ReceivedName start///////////
    if(item.ReceivedName != ''){
        let dataInfo = [];
        let tablaY = 752;
        let textoY = 757;
        dataInfo.push({ name: "Received by / Recibido por:" });
        dataInfo.push({ name: item.ReceivedName });
        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                 dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = ancho/3 -5;

        doc.autoTable(columns, dataInfo, {
            startY: tablaY,
            theme: 'plain',
            columnWidth: textoY,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 32+((ancho/3)*2) },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: font, 
                halign: "left", 
                valign: "middle",
                cellWidth: tableWidthBody,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
        });
    }
    doc.setDrawColor("#000");

    let fileName = documento;

    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function getExcelTransactionReversals(rowData, titulo, excel, CraneName, motive, companyName) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    const hoy = new Date();

    if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }
        
    //if (isUndefined(un)) un = 'TON';
    if (excel) {

        let tituloExcel = "";
        if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
            tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
            this.dataBuque.CountryName = this.itinerarySelected.CountryName;
            this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        }

        if (y < 10) letra = 10;
        worksheet.mergeCells('A1:B2');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B2');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        if(companyName){
            worksheet.mergeCells("C1:" + numeroLetra(y) + "2")   
        }else if(companyName === undefined){ 
            worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
            worksheet.mergeCells("C2:" + numeroLetra(y) + "2");
        }

        const customCellCompanyBranch = worksheet.getCell("C1");

        const row1 = worksheet.getRow(1);
        row1.height = companyName ? 60 : 30;
        customCellCompanyBranch.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCellCompanyBranch.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCellCompanyBranch.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            }
        }
        customCellCompanyBranch.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
        customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            
        if(companyName){
        }else if(companyName === undefined){     
            const row2 = worksheet.getRow(2);    
            row2.height = 30;
            const customCell = worksheet.getCell("C2");
            customCell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCell.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell.value = tituloExcel;
            customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }

        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y/4);
        let rango1 = numeroLetra(division*1);
        let rango11 = numeroLetra((division * 1) +1);
        let rango2 = numeroLetra(division*2);
        let rango21 = numeroLetra((division * 2) +1);
        let rango3 = numeroLetra(division*3);
        let rango31 = numeroLetra((division * 3) +1);
        let rango4 = numeroLetra(y);

        //grua
        worksheet.mergeCells("A4:" + rango1 +"4");
        const customCell3 = worksheet.getCell("A4");
        customCell3.height = 50;
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = this.$t('label.crane')+': '+CraneName;
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //reason
        worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
        const customCell4 = worksheet.getCell(rango11+"4");
        customCell4.height = 50;
        customCell4.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell4.value = this.$t('label.motive')+': '+motive;
        customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
       
        customCell4.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //printed
        worksheet.mergeCells(rango21 + "4:" + rango3 + "4");
        const customCell5 = worksheet.getCell(rango21+"4");
        customCell5.height = 50;
        customCell5.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell5.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell5.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); 
        customCell5.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //user
        worksheet.mergeCells(rango31 + "4:" + rango4 + "4");
        const customCell6 = worksheet.getCell(rango31+"4");
        customCell6.height = 50;
        customCell6.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        
        customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell6.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        customCell6.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //linea
        worksheet.mergeCells("A5:Q5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };

    headerRow.height = 30;
    
    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }
        
        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data; 
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })

    rowData.map(async function(data){

            let dataRow = worksheet.addRow();
            let indice = 0;
            
            keyColumns.map(function (data2) {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
 
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function getOperationsSummary(item, rowDataProvider, rowDataProviderQuantity, titulo) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];

    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

    let widthImage = 100;
    let positionX = 110; 
    let sizeText = 7.5; //8;
    let tableWidthBody = 'auto';

    let font = 7.5; //8;
    let resta = 0;
    let tcentro = 300;
    let ancho = 535;
    let anchoReal = 595;
    let largo = 842;


    const doc = new jsPDF({
        orientation: 'p',
        unit: 'pt',
        format: [600, largo],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        let fecha  = DateFormater.formatTimeZoneToDateTime(hoy);
        let footerY = 800;
        //doc.setFontSize(8);
        doc.setFontSize(7.5);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed')+": "+ fecha, 40, footerY, {
                align: 'left'
            });
            if(this.loadText !== undefined && this.loadText !== null){
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: "+this.loadText, doc.internal.pageSize.width - 50, footerY, {
                    align: 'right'
                });
            }else{
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, footerY, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, footerY-15, {           
                align: 'right'
            })
        }
    }

    let voyajeData = '';
    if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
        this.dataBuque.DatePlanning = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName;
        voyajeData = this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
        this.dataBuque.CountryName = this.itinerarySelected.CountryName;
        this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
    }

    doc.setDrawColor("#fff");
    doc.rect(30, 43, widthImage, 63);
    doc.addImage(myBase64Image, "JPEG", 30, 43, widthImage, 63);

    doc.setDrawColor("#fff");
 /*   doc.rect(positionX, 40,ancho-widthImage-15, 40);
    doc.setFontSize(9);
    doc.text(this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName, tcentro, 65, 'center');
*/
    doc.setDrawColor("#fff");
    if(this.dataBuque.DatePlanning != ''){
        let dataInfo = [];
        dataInfo.push({ name: this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName });
        dataInfo.push({ name: this.dataBuque.DatePlanning });
        dataInfo.push({ name: voyajeData });
        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = widthImage+30;

        doc.autoTable(columns, dataInfo, {
            startY: 55,
            theme: 'plain',
            columnWidth: (ancho - tableWidthBody),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 35+widthImage },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: 9, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho - widthImage + 10),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    doc.setFillColor("#cccccc");
    doc.rect(30, 105, ancho, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(30, 110, ancho, 25, "FD");
    doc.setFontSize(sizeText);
    doc.text(titulo, tcentro, 125, 'center');

    //-------------------------------cuadro 1 fila 1
    let cuadroY = 150;
    let textoY = 160;
    let cuadroAlto = 20;

    doc.setDrawColor("#000");
    doc.setFillColor("#EDEDED");    doc.rect(30, cuadroY, ancho/14-10, cuadroAlto, "FD"); 
    doc.rect(20+ancho/14, cuadroY, ancho/7, cuadroAlto);
    doc.setFillColor("#EDEDED");    doc.rect(20+ancho/14*3, cuadroY, ancho/14, cuadroAlto, "FD"); 
    doc.rect(20+ancho/14*4, cuadroY, ancho/7, cuadroAlto);
    doc.setFillColor("#EDEDED");    doc.rect(20+ancho/14*6, cuadroY, ancho/7, cuadroAlto, "FD");
    doc.rect(20+ancho/14*8, cuadroY, ancho/7, cuadroAlto);
    doc.setFillColor("#EDEDED");    doc.rect(20+ancho/14*10, cuadroY, ancho/7+10, cuadroAlto, "FD");
    doc.rect(30+ancho/14*12, cuadroY, ancho/7, cuadroAlto);

    if (item.Ata !== '') {
        doc.text('ATA', 35, textoY, 'left'); 
        doc.text(item.Ata, 25+ancho/14, textoY, 'left');
    } else {
        doc.text('ETA', 35, textoY, 'left'); 
        doc.text(item.Eta, 25+ancho/14, textoY, 'left');
    }

    if (item.Etd !== '') {
        doc.text('ETD', 25+ancho/14*3, textoY, 'left'); 
        doc.text(item.Etd, 25+ancho/14*4, textoY, 'left');
    } else {
        doc.text('ATD', 25+ancho/14*3, textoY, 'left'); 
        doc.text(item.Atd, 25+ancho/14*4, textoY, 'left');
    }
    
    doc.text(this.$t('label.VOYAGE_ARRIVAL'), 25+ancho/14*6, textoY, 'left'); 
    doc.text(item.VoyageArrival, 25+ancho/14*8, textoY, 'left');
    doc.text(this.$t('label.VOYAGE_DEPARTURE'), 25+ancho/14*10, textoY, 'left'); 
    doc.text(item.VoyageDeparture, 35+ancho/14*12, textoY, 'left');

    //-------------------------------cuadro 1 fila 2
    cuadroY = 170;
    textoY = 180;
    cuadroAlto = 20;

    doc.setDrawColor(0);
    //doc.setDrawColor("#000");
    doc.setFillColor("#EDEDED");    doc.rect(30, cuadroY, ancho/6, cuadroAlto, "FD"); 
    doc.rect(30+ancho/6, cuadroY, ancho/6*2, cuadroAlto);
    doc.setFillColor("#EDEDED");    doc.rect(30+ancho/6*3, cuadroY, ancho/6, cuadroAlto, "FD"); 
    doc.rect(30+ancho/6*4, cuadroY, ancho/6*2, cuadroAlto);

    doc.text(this.$t('label.originPort'), 35, textoY, 'left'); 
    doc.text(item.PortOriginName, 35+ancho/6, textoY, 'left');
    doc.text(this.$t('label.destinationPort'), 35+ancho/6*3, textoY, 'left'); 
    doc.text(item.PortDestinationName, 35+ancho/6*4, textoY, 'left');

    //-------------------------------cuadro 2 fila 1
    cuadroY = 200;
    textoY = 210;
    cuadroAlto = 20;

    doc.setFillColor("#EDEDED");    doc.rect(30, cuadroY, ancho/8*2, 3*cuadroAlto, "FD"); 
    doc.setFillColor("#1a3760");    doc.rect(30+ancho/8*2, cuadroY, ancho/8, cuadroAlto, "FD");
    doc.rect(30+ancho/8*3, cuadroY, ancho/8, cuadroAlto);
    doc.setFillColor("#ec631b");    doc.rect(30+ancho/8*2, cuadroY+cuadroAlto, ancho/8, cuadroAlto, "FD");
    doc.rect(30+ancho/8*3, cuadroY+cuadroAlto, ancho/8, cuadroAlto);
    doc.setFillColor("#5d6164");    doc.rect(30+ancho/8*2, cuadroY+cuadroAlto*2, ancho/8, cuadroAlto, "FD");
    doc.rect(30+ancho/8*3, cuadroY+cuadroAlto*2, ancho/8, cuadroAlto);

    doc.setFillColor("#EDEDED");    doc.rect(30+ancho/8*4, cuadroY, ancho/8*2, 3*cuadroAlto, "FD"); 
    doc.setFillColor("#1a3760");    doc.rect(30+ancho/8*6, cuadroY, ancho/8, cuadroAlto, "FD");
    doc.rect(30+ancho/8*7, cuadroY, ancho/8, cuadroAlto);
    doc.setFillColor("#ec631b");    doc.rect(30+ancho/8*6, cuadroY+cuadroAlto, ancho/8, cuadroAlto, "FD");
    doc.rect(30+ancho/8*7, cuadroY+cuadroAlto, ancho/8, cuadroAlto);    
    doc.setFillColor("#5d6164");    doc.rect(30+ancho/8*6, cuadroY+cuadroAlto*2, ancho/8, cuadroAlto, "FD");
    doc.rect(30+ancho/8*7, cuadroY+cuadroAlto*2, ancho/8, cuadroAlto);

    doc.text(this.$t('label.unloadContainers'), 35, textoY, 'left'); 
    doc.setTextColor("#fff");   doc.text(this.$t('label.containersFull'), 35+ancho/8*2, textoY, 'left'); 
    doc.setTextColor("#000");   doc.text(item.DischargeFull.toString(), 35+ancho/8*3, textoY, 'left');
    doc.setTextColor("#fff");   doc.text(this.$t('label.containersEmpty'), 35+ancho/8*2, textoY+cuadroAlto, 'left'); 
    doc.setTextColor("#000");   doc.text(item.DischargeEmpty.toString(), 35+ancho/8*3, textoY+cuadroAlto, 'left');
    doc.setTextColor("#fff");   doc.text('OOG', 35+ancho/8*2, textoY+cuadroAlto*2, 'left'); 
    doc.setTextColor("#000");   doc.text(item.DischargeOog.toString(), 35+ancho/8*3, textoY+cuadroAlto*2, 'left');

    doc.text(this.$t('label.shippingContainers'), 35+ancho/8*4, textoY, 'left'); 
    doc.setTextColor("#fff");   doc.text(this.$t('label.containersFull'), 35+ancho/8*6, textoY, 'left'); 
    doc.setTextColor("#000");   doc.text(item.LoadFull.toString(), 35+ancho/8*7, textoY, 'left');
    doc.setTextColor("#fff");   doc.text(this.$t('label.containersEmpty'), 35+ancho/8*6, textoY+cuadroAlto, 'left'); 
    doc.setTextColor("#000");   doc.text(item.LoadEmpty.toString(), 35+ancho/8*7, textoY+cuadroAlto, 'left');
    doc.setTextColor("#fff");   doc.text('OOG', 35+ancho/8*6, textoY+cuadroAlto*2, 'left'); 
    doc.setTextColor("#000");   doc.text(item.LoadOog.toString(), 35+ancho/8*7, textoY+cuadroAlto*2, 'left');

    //-------------------------------cuadro 2 fila 2
    cuadroY = 260;
    textoY = 270;
    cuadroAlto = 30;

    doc.setFillColor("#EDEDED");    doc.rect(30, cuadroY, ancho/8*3, cuadroAlto, "FD"); 
    doc.rect(30+ancho/8*3, cuadroY, ancho/8, cuadroAlto);
    doc.setFillColor("#EDEDED");    doc.rect(30+ancho/8*4, cuadroY, ancho/8*3, cuadroAlto, "FD");
    doc.rect(30+ancho/8*7, cuadroY, ancho/8, cuadroAlto);

    doc.text(this.$t('label.sealingEmptyContainers'), 35, textoY, 'left'); 
    doc.text(item.SealEmptyCont.toString(), 35+ancho/8*3, textoY, 'left'); 
    //doc.text('', 35+ancho/8*7, textoY+cuadroAlto, 'left'); 

    let dataInfo = [];
    dataInfo.push({ name: this.$t('label.supplementForSealingEmptyContainers') });
    let columnsDataInfo = [];
    let columnDefs = Object.keys(dataInfo[0]);

    for (let index = 0; index < columnDefs.length; index++) {
        columnsDataInfo.push({
            dataKey: columnDefs[index]
        });
    }
    let tableWidthBodyDataInfo = 190;

    doc.autoTable(columnsDataInfo, dataInfo, {
        startY: textoY-8,
        theme: 'plain',
        columnWidth: ancho/8*3,
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        
        tableWidth: tableWidthBodyDataInfo,
        margin: { left: 35+ancho/8*4 },
        styles: { 
            cellPadding: { bottom: 2 },
            margin: { bottom: 2 },
            fontSize: font, 
            halign: "left", 
            valign: "middle",
            cellWidth: tableWidthBodyDataInfo,
        },
        headStyles: {
            textColor: "#000",
            halign: "left",
        },
    });
    doc.setDrawColor(0);

    //-------------------------------cuadro 2 fila 3
    cuadroY = 290;
    textoY = 300;
    cuadroAlto = 20;

    doc.setFillColor("#EDEDED");    doc.rect(30, cuadroY, ancho/8*3, cuadroAlto, "FD"); 
    doc.rect(30+ancho/8*3, cuadroY, ancho/8, cuadroAlto);
    doc.setFillColor("#EDEDED");    doc.rect(30+ancho/8*4, cuadroY, ancho/8*3, cuadroAlto, "FD");
    doc.rect(30+ancho/8*7, cuadroY, ancho/8, cuadroAlto);

    doc.text(this.$t('label.mobilizationOfTwinboxes'), 35, textoY, 'left'); 
    doc.text(item.TwinBox.toString(), 35+ancho/8*3, textoY, 'left'); 
    doc.text(this.$t('label.mobilizationOfShipHatch'), 35+ancho/8*4, textoY, 'left');
    doc.text(item.HatchCover.toString(), 35+ancho/8*7, textoY, 'left'); 

    //-------------------------------cuadro 3 fila 1
    cuadroY = 320;
    textoY = 330;
    cuadroAlto = 20;  

    doc.setFillColor("#EDEDED");    doc.rect(30, cuadroY, ancho/5*2, cuadroAlto, "FD"); 
    doc.setFillColor("#EDEDED");    doc.rect(30+ancho/5*2, cuadroY, ancho/5*2, cuadroAlto, "FD");
    doc.setFillColor("#EDEDED");    doc.rect(30+ancho/5*4, cuadroY, ancho/5, cuadroAlto, "FD");

    doc.text(this.$t('label.item'), 35, textoY, 'left'); 
    doc.text(this.$t('label.providers'), 35+ancho/5*2, textoY, 'left'); 
    doc.text(this.$t('label.quantity'), 92+ancho/5*4, textoY, 'left');

    //-------------------------------cuadro 3 fila 2
    cuadroY = 340;
    textoY = 350;
    cuadroAlto = 380;  

    doc.rect(30, cuadroY, ancho/5*2, cuadroAlto); 
    doc.rect(30+ancho/5*2, cuadroY, ancho/5*2, cuadroAlto);
    doc.rect(30+ancho/5*4, cuadroY, ancho/5, cuadroAlto);

    let tablaY = 345;
    if(rowDataProvider.length > 0){
        let columns = [];
        let columnDefs = Object.keys(rowDataProvider[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 400;

        doc.autoTable(columns, rowDataProvider, {
            startY: tablaY,
            theme: 'plain',
            columnWidth: ancho/5*2,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 35 },
            styles: { 
                cellPadding: { bottom: 3 },
                margin: { bottom: 3 },
                fontSize: font, 
                halign: "left", 
                valign: "middle",
                cellWidth: ancho/5*2,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
            columnStyles: { 2: { halign: 'left', cellWidth: ancho/3, } },
        });
    }


    if(rowDataProviderQuantity.length > 0){
        let columns = [];
        let columnDefs = Object.keys(rowDataProviderQuantity[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 70;

        doc.autoTable(columns, rowDataProviderQuantity, {
            startY: tablaY,
            theme: 'plain',
            columnWidth: ancho/5*2,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 465 },
            styles: { 
                cellPadding: { bottom: 3 },
                margin: { bottom: 3 },
                fontSize: font, 
                halign: "right", 
                valign: "middle",
                cellWidth: (ancho/5)-12,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
            columnStyles: { 2: { halign: 'left', cellWidth: ancho/3, } },
        });
    }
    doc.setDrawColor(0);

    //-------------------------------cuadro 4 fila 1
    cuadroY = 730;
    textoY = 740;
    cuadroAlto = 20;  

    doc.setFillColor("#EDEDED");    doc.rect(30, cuadroY, ancho/5, cuadroAlto, "FD"); 
    doc.rect(30+ancho/5, cuadroY, ancho/5, cuadroAlto);
    doc.rect(30+ancho/5*2, cuadroY, ancho/5, cuadroAlto);
    doc.rect(30+ancho/5*3, cuadroY, ancho/5, cuadroAlto);
    doc.rect(30+ancho/5*4, cuadroY, ancho/5, cuadroAlto);

    doc.text(this.$t('label.Timeslost'), 35, textoY, 'left'); 
    doc.text(this.$t('label.delaysByVessel'), 35+ancho/5, textoY, 'left'); 
    doc.text(this.$t('label.delaysByOperator'), 32+ancho/5*2, textoY, 'left');
    doc.text(this.$t('label.delaysPerPort'), 35+ancho/5*3, textoY, 'left');
    doc.text(this.$t('label.others'), 35+ancho/5*4, textoY, 'left');

    //-------------------------------cuadro 4 fila 1
    cuadroY = 750;
    textoY = 760;
    cuadroAlto = 20;  

    doc.setFillColor("#EDEDED");    doc.rect(30, cuadroY, ancho/5, cuadroAlto, "FD"); 
    doc.rect(30+ancho/5, cuadroY, ancho/5, cuadroAlto);
    doc.rect(30+ancho/5*2, cuadroY, ancho/5, cuadroAlto);
    doc.rect(30+ancho/5*3, cuadroY, ancho/5, cuadroAlto);
    doc.rect(30+ancho/5*4, cuadroY, ancho/5, cuadroAlto);

    doc.text(this.$t('label.duration'), 35, textoY, 'left'); 
    doc.text(item.DelaysByVessel, 35+ancho/5, textoY, 'left'); 
    doc.text(item.DelaysByOperator, 35+ancho/5*2, textoY, 'left');
    doc.text(item.DelaysPerPort, 35+ancho/5*3, textoY, 'left');
    doc.text(item.Other, 35+ancho/5*4, textoY, 'left');

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function orderMetalScrapPdf(dataGeneral, incidenceData, fullWeighingData, emptyWeighingData, shimpmentData, titulo, isAnnulled) {
    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
    
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

    let widthImage = 69;
    let positionX = 110; 
    let sizeText = 8;
    let ancho = 535;
    let largo = 842;

    const doc = new jsPDF({
        orientation: 'p',
        unit: 'pt',
        format: [600, largo],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        let fecha  = DateFormater.formatTimeZoneToDateTime(hoy);
        let footerY = 440;
        
        doc.setFontSize(7.5);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed')+": "+ fecha, 30, footerY, {
                align: 'left'
            });
            if(this.loadText !== undefined && this.loadText !== null){
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: "+this.loadText, doc.internal.pageSize.width - 40, footerY, {
                    align: 'right'
                });
            }else{
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 40, footerY, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 40, footerY-15, {           
                align: 'right'
            })
        }
    }

    let voyajeData = '';
    if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
        this.dataBuque.DatePlanning = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName;
        voyajeData = this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
        this.dataBuque.CountryName = this.itinerarySelected.CountryName;
        this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
    }

    doc.setDrawColor("#fff");
    doc.rect(30, 43, widthImage, 63);
    doc.addImage(myBase64Image, "JPEG", 30, 43, widthImage, 63);


    doc.setDrawColor("#fff");
    doc.rect(positionX, 40,ancho-widthImage-15, 40);
    doc.setFontSize(9);

    doc.setDrawColor("#fff");
    if(this.dataBuque.DatePlanning != ''){
        let dataInfo = [];
        dataInfo.push({ name: this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName });
        dataInfo.push({ name: this.dataBuque.DatePlanning });
        dataInfo.push({ name: voyajeData });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = widthImage+30;

        doc.autoTable(columns, dataInfo, {
            startY: 60,
            theme: 'plain',
            columnWidth: (ancho - tableWidthBody),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 35+ancho/7 },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho - tableWidthBody),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }
    doc.setFontSize(sizeText);

    //-------------------------------cuadro 1 fila 1
    let cuadroY = 105;
    let textoY = 114;
    let tablaY = 107;
    let cuadroAlto = 20;

    doc.setDrawColor("#000");
    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho/7, cuadroAlto, "FD"); 
    doc.rect(30+ancho/7, cuadroY, 5*ancho/14, cuadroAlto);
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/2, cuadroY, ancho/14, cuadroAlto, "FD"); 
    doc.rect(30+ancho/7*4, cuadroY, ancho/7, cuadroAlto);
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/7*5, cuadroY, ancho/7, cuadroAlto, "FD");
    doc.rect(30+ancho/7*6, cuadroY, ancho/7, cuadroAlto);

    if(dataGeneral != undefined ){
        doc.text(this.$t('label.transport'), 35, textoY, 'left'); 
        if(dataGeneral.ClientName != ''){
            let dataInfo = [];
            dataInfo.push({ name: dataGeneral.ClientRif+' - '+dataGeneral.ClientName });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = 190;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: textoY,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 35+ancho/7 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000");
        doc.text(this.$t('label.date'), 35+ancho/2, textoY, 'left'); 
        doc.text(dataGeneral.TransactionDate, 35+ancho/7*4, textoY, 'left');
        doc.text(this.$t('label.noOrder'), 33+ancho/7*5, textoY, 'left'); 
        //doc.setFont("helvetica", "bold");
        //doc.text(dataGeneral.OrderCode, 35+ancho/7*6, textoY, 'left');
 
        if(dataGeneral.OrderCode != ''){
            let dataInfo= [];
            dataInfo.push({ name: dataGeneral.OrderCode });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = 70;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: tableWidthBody,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 35+ancho/7*6 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                    fontStyle: 'bold',
                    textColor: "#000"
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000");
    }
    //-------------------------------cuadro 1 fila 2
    doc.setFont("helvetica", "normal");

    cuadroY = 125;
    textoY = 134;
    tablaY = 127;
    
    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho/7, cuadroAlto, "FD"); 
    doc.rect(30+ancho/7, cuadroY, 5*ancho/14, cuadroAlto);
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/2, cuadroY, ancho/14, cuadroAlto, "FD"); 
    doc.rect(30+ancho/7*4, cuadroY, ancho/7, cuadroAlto);
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/7*5, cuadroY, ancho/7, cuadroAlto, "FD"); 
    doc.rect(30+ancho/7*6, cuadroY, ancho/7, cuadroAlto);

    if(dataGeneral != undefined ){
        doc.text(this.$t('label.driver'), 35, textoY, 'left'); 
        if(dataGeneral.DriverName != ''){
            let dataInfo= [];
            dataInfo.push({ name: dataGeneral.DriverCi+' - '+dataGeneral.DriverName });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = 190;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: tableWidthBody,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 35+ancho/7 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000"); 
        doc.text(this.$t('label.plate'), 35+ancho/2, textoY, 'left'); 
        doc.text(dataGeneral.LicensePlate, 35+ancho/7*4, textoY, 'left');
        doc.text(this.$t('label.Gamela'), 33+ancho/7*5, textoY, 'left'); 

        if(dataGeneral.ImplementAlias != ''){
            let dataInfo= [];
            dataInfo.push({ name: dataGeneral.ImplementAlias });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = 70;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: tableWidthBody,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 35+ancho/7*6 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000");
    }

    //-------------------------------cuadro 1 fila 3
    doc.setFont("helvetica", "normal");

    cuadroY = 145;
    textoY = 154;
    tablaY = 147;

    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho/7, cuadroAlto, "FD"); 
    doc.rect(30+ancho/7, cuadroY, 5*ancho/14, cuadroAlto);
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/2, cuadroY, ancho/14, cuadroAlto, "FD"); 

    doc.rect(30+ancho/7*4, cuadroY, 3*ancho/7, cuadroAlto);

    if(dataGeneral != undefined ){
        doc.text(this.$t('label.clientYard'), 35, textoY, 'left'); 
        if(dataGeneral.YardClientName != ''){
            let dataInfo= [];
            dataInfo.push({ name: dataGeneral.YardClientRif+' - '+dataGeneral.YardClientName });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = 190;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: tableWidthBody,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 35+ancho/7 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000"); 
        doc.text(this.$t('label.yard'), 35+ancho/2, textoY, 'left'); 
        doc.text(dataGeneral.YardName, 35+ancho/7*4, textoY, 'left');
    }

    //-------------------------------cuadro 2 fila 1
    cuadroY = 170;
    textoY = 177;
    cuadroAlto = 10;

    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho/2, cuadroAlto, "FD"); 
    doc.text(this.$t('label.emptyWeighing'), 35+ancho/4, textoY, 'center'); 

    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/2, cuadroY, ancho/2, cuadroAlto, "FD"); 
    doc.text(this.$t('label.fullWeighing'), ancho/8*6, textoY, 'center');

    //-------------------------------cuadro 2 fila 2
    cuadroY = 180;
    textoY = 189;
    cuadroAlto = 20;
    tablaY = 183;
    doc.rect(30, cuadroY, ancho/6, cuadroAlto); 
    doc.rect(30+ancho/6, cuadroY, ancho/6, cuadroAlto);
    doc.rect(30+ancho/3, cuadroY, ancho/6, cuadroAlto); 
    doc.rect(30+ancho/2, cuadroY, ancho/6, cuadroAlto);
    doc.rect(30+ancho/6*4, cuadroY, ancho/6, cuadroAlto); 
    doc.rect(30+ancho/6*5, cuadroY, ancho/6, cuadroAlto);
    doc.setTextColor(0);
    if(emptyWeighingData != undefined ){
        if(emptyWeighingData.WeightTon != '' || emptyWeighingData.WeightKgs != '' ){
            let dataInfo= [];
            let slash = (emptyWeighingData.WeightTon != '') ? ' / ' : ' ';
            dataInfo.push({ 
                name: formatMilDecimal(emptyWeighingData.WeightKgs)+slash+formatMilDecimal(emptyWeighingData.WeightTon) 
            });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = ancho/6 - 10;
            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: tableWidthBody,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 33 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000"); 
        doc.setTextColor(0);
        doc.text(emptyWeighingData.TransactionDate, 35+ancho/6, textoY, 'left');

        let columns =[{
            dataKey: 'name'
        }];
        let dataInfo = [{
            name: emptyWeighingData.TruckWeghingScaleName
        }];
        let tableWidthBody = ancho/6 - 10;
        doc.autoTable(columns, dataInfo, {
            startY: tablaY,
            theme: 'plain',
            columnWidth: tableWidthBody,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 33+ancho/3 },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "left", 
                valign: "middle",
                cellWidth: tableWidthBody,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
        });
        
    }

    if(fullWeighingData != undefined){
        if(fullWeighingData.WeightTon != '' || fullWeighingData.WeightKgs != '' ){
            let dataInfo= [];
            let slash = (fullWeighingData.WeightTon != '') ? ' / ' : ' ';
            dataInfo.push({ 
                name: formatMilDecimal(fullWeighingData.WeightKgs)+slash+formatMilDecimal(fullWeighingData.WeightTon) });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = ancho/6 - 10;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: tableWidthBody,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 33+ancho/2 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000"); 
        doc.setTextColor(0);       
        doc.text(fullWeighingData.TransactionDate, 35+ancho/6*4, textoY, 'left');
        
        let columns =[{
            dataKey: 'name'
        }];
        let dataInfo = [{
            name: fullWeighingData.TruckWeghingScaleName
        }];
        let tableWidthBody = ancho/6 - 10;
        doc.autoTable(columns, dataInfo, {
            startY: tablaY,
            theme: 'plain',
            columnWidth: tableWidthBody,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 33+ancho/6*5 },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "left", 
                valign: "middle",
                cellWidth: tableWidthBody,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
        });
    }

    /****************************solo cuadros************************** */

    //-------------------------------cuadro 3 fila 1 
    cuadroY = 205;
    textoY = 214;
    tablaY = 207;
    cuadroAlto = 20;
    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho/8, cuadroAlto, "FD"); 
    doc.rect(30+ancho/8, cuadroY, ancho/8*2, cuadroAlto);
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/8*3, cuadroY, 20+ancho/8, cuadroAlto, "FD");
    doc.rect(50+ancho/8*4, cuadroY, ancho/8, cuadroAlto);
    doc.setFillColor("#EDEDED");
    doc.rect(50+ancho/8*5, cuadroY, (ancho/8)-20, cuadroAlto, "FD");
    doc.rect(30+ancho/8*6, cuadroY, ancho/8*2, cuadroAlto);

    //-------------------------------cuadro 4 fila 1 
    cuadroY = 230;
    textoY = 227;
    cuadroAlto = 10;

    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho, cuadroAlto, "FD"); 

    //-------------------------------cuadro 4 fila 2 
    cuadroY = 240;
    textoY = 248;
    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho/6, cuadroAlto, "FD"); 

    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/6, cuadroY, ancho/6, cuadroAlto, "FD");

    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/6*2, cuadroY, ancho/6, cuadroAlto, "FD"); 
    
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/6*3, cuadroY, ancho/6, cuadroAlto, "FD");
    
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/6*4, cuadroY, ancho/6*2, cuadroAlto, "FD"); 
    
    //-------------------------------cuadro 4 fila 3
    cuadroY = 250;
    textoY = 259;
    tablaY = 253;
    cuadroAlto = 20;

    doc.rect(30, cuadroY, ancho/6, cuadroAlto); 
    doc.rect(30+ancho/6, cuadroY, ancho/6, cuadroAlto);
    doc.rect(30+ancho/6*2, cuadroY, ancho/6, cuadroAlto); 
    doc.rect(30+ancho/6*3, cuadroY, ancho/6, cuadroAlto);
    doc.rect(30+ancho/6*4, cuadroY, ancho/6*2, cuadroAlto); 

    //-------------------------------cuadro 5 fila 1 
    cuadroY = 275;
    textoY = 282;
    cuadroAlto = 10;

    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho, cuadroAlto, "FD"); 
    //-------------------------------cuadro 5 fila 2 
    cuadroY = 285;
    textoY = 293;

    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho/9, cuadroAlto, "FD"); 

    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/9, cuadroY, ancho/9*2, cuadroAlto, "FD");

    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/9*3, cuadroY, ancho/9*2, cuadroAlto, "FD"); 
    
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/9*5, cuadroY, ancho/9*2, cuadroAlto, "FD");
    
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/9*7, cuadroY, ancho/9*2, cuadroAlto, "FD"); 

    //-------------------------------cuadro 5 fila 3
    tablaY = 300;
    doc.setDrawColor("#000");
   
    if(isAnnulled){
        doc.setFontSize(80);
        doc.setTextColor("#c1c5c3");
        doc.text(this.$t('label.canceledDocument'), 150, 390, null, 25);
        doc.text(this.$t('label.canceledDocument'), 150, 690, null, 25);
    }
    doc.setTextColor(0);
    doc.setDrawColor("#000");
    doc.setFontSize(sizeText);
    /******************************************* */
    //-------------------------------cuadro 3 fila 1 
    cuadroY = 205;
    textoY = 214;
    tablaY = 207;
    cuadroAlto = 20;
    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho/8, cuadroAlto, "FD"); 
    doc.rect(30+ancho/8, cuadroY, ancho/8*2, cuadroAlto);
    doc.setFillColor("#EDEDED");
    doc.rect(30+ancho/8*3, cuadroY, 20+ancho/8, cuadroAlto, "FD");
    doc.rect(50+ancho/8*4, cuadroY, ancho/8, cuadroAlto);
    doc.setFillColor("#EDEDED");
    doc.rect(50+ancho/8*5, cuadroY, (ancho/8)-20, cuadroAlto); 
    doc.rect(30+ancho/8*6, cuadroY, ancho/8*2, cuadroAlto);

    if(dataGeneral != undefined ){
        doc.text(this.$t('label.commodity'), 35, textoY, 'left'); 
        if(dataGeneral.CommodityName != ''){
            let dataInfo= [];
            dataInfo.push({ name: dataGeneral.CommodityName });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = 125;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: tableWidthBody,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 35+ancho/8 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000");
        doc.text(this.$t('label.netWeight')+' (KG)', 35+ancho/8*3, textoY, 'left');
        if(dataGeneral.NetWeight != '') doc.text(dataGeneral.NetWeight, 55+ancho/8*4, textoY, 'left');
        doc.text(this.$t('label.client'), 55+ancho/8*5, textoY, 'left'); 

        if(dataGeneral.BlClientName != ''){
            let dataInfo= [];
            dataInfo.push({ name: dataGeneral.BlClientRif+' '+dataGeneral.BlClientName });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = 125;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: tableWidthBody,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 35+ancho/8*6 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000");
    }
    //-------------------------------cuadro 4 fila 1 
    cuadroY = 230;
    textoY = 237;
    cuadroAlto = 10;

    doc.rect(30, cuadroY, ancho, cuadroAlto); 
    doc.text(this.$t('label.shipment'), 85+ancho/6*2, textoY, 'left'); 

    //-------------------------------cuadro 4 fila 2 
    cuadroY = 240;
    textoY = 248;

    doc.rect(30, cuadroY, ancho/6, cuadroAlto); 
    doc.text(this.$t('label.crane'), 35, textoY, 'left'); 

    doc.rect(30+ancho/6, cuadroY, ancho/6, cuadroAlto);
    doc.text(this.$t('label.hold'), 35+ancho/6, textoY, 'left'); 

    doc.rect(30+ancho/6*2, cuadroY, ancho/6, cuadroAlto); 
    doc.text(this.$t('label.dateFrom'), 35+ancho/6*2, textoY, 'left'); 

    doc.rect(30+ancho/6*3, cuadroY, ancho/6, cuadroAlto);
    doc.text(this.$t('label.dateTo'), 35+ancho/6*3, textoY, 'left');

    doc.rect(30+ancho/6*4, cuadroY, ancho/6*2, cuadroAlto); 
    doc.text(this.$t('label.checker'), 35+ancho/6*4, textoY, 'left'); 

    //-------------------------------cuadro 4 fila 3
    cuadroY = 250;
    textoY = 259;
    tablaY = 253;
    cuadroAlto = 20;

    doc.rect(30, cuadroY, ancho/6, cuadroAlto); 
    doc.rect(30+ancho/6, cuadroY, ancho/6, cuadroAlto);
    doc.rect(30+ancho/6*2, cuadroY, ancho/6, cuadroAlto); 
    doc.rect(30+ancho/6*3, cuadroY, ancho/6, cuadroAlto);
    doc.rect(30+ancho/6*4, cuadroY, ancho/6*2, cuadroAlto); 

    if(shimpmentData != undefined ){
        doc.text(shimpmentData.CraneAlias, 35, textoY, 'left'); 
        doc.text(shimpmentData.VesselHoldName, 35+ancho/6, textoY, 'left'); 
        doc.text(shimpmentData.TransactionBegin, 35+ancho/6*2, textoY, 'left'); 
        doc.text(shimpmentData.TransactionFinish, 35+ancho/6*3, textoY, 'left');
        if(shimpmentData.TransaLogin != ''){
            let dataInfo = [];
            dataInfo.push({ name: shimpmentData.TransaLogin+' '+shimpmentData.TransaLoginName });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = 170;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: tableWidthBody,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 35+ancho/6*4 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000");
    }   
    //-------------------------------cuadro 5 fila 1 
    cuadroY = 275;
    textoY = 282;
    cuadroAlto = 10;

    doc.rect(30, cuadroY, ancho, cuadroAlto); 
    doc.text(this.$t('label.incidences'), 85+ancho/6*2, textoY, 'left'); 
    //-------------------------------cuadro 5 fila 2 
    cuadroY = 285;
    textoY = 293;

    doc.rect(30, cuadroY, ancho/9, cuadroAlto); 
    doc.text('#', 35+27, textoY, 'left'); 

    doc.rect(30+ancho/9, cuadroY, ancho/9*2, cuadroAlto);
    doc.text(this.$t('label.type'), 35+ancho/9, textoY, 'left'); 


    doc.rect(30+ancho/9*3, cuadroY, ancho/9*2, cuadroAlto); 
    doc.text(this.$t('label.dateFrom'), 35+ancho/9*3, textoY, 'left'); 


    doc.rect(30+ancho/9*5, cuadroY, ancho/9*2, cuadroAlto);
    doc.text(this.$t('label.dateTo'), 35+ancho/9*5, textoY, 'left');


    doc.rect(30+ancho/9*7, cuadroY, ancho/9*2, cuadroAlto); 
    doc.text(this.$t('label.duration'), 35+ancho/9*7, textoY, 'left'); 

    //-------------------------------cuadro 5 fila 3
    tablaY = 300;
    if(incidenceData.length > 0){
        let columns = [];
        let columnDefs = Object.keys(incidenceData[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 400;

        doc.autoTable(columns, incidenceData, {
            startY: tablaY,
            theme: 'plain',
            columnWidth: ancho/9*2,
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: tableWidthBody,
            margin: { left: 35 },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "left", 
                valign: "middle",
                cellWidth: ancho/9*2,
            },
            headStyles: {
                textColor: "#000",
                halign: "left",
            },
            columnStyles: { 0: { halign: 'center', cellWidth: ancho/9, } },
        });
    }
    doc.setDrawColor("#000");


    /********************************************* */
   //-------------------------------cuadro 6 fila 1 
    cuadroY = 350;
    textoY = 359;
    cuadroAlto = 20;
    tablaY = 351;

    doc.setFillColor("#EDEDED");
    doc.rect(30, cuadroY, ancho/7, cuadroAlto, "FD"); 
    doc.rect(30+ancho/7, cuadroY, ancho/7*6, cuadroAlto);
    if(dataGeneral != undefined ){
        doc.text(this.$t('label.observation'), 35, textoY, 'left'); 
        if(dataGeneral.Observation != ''){
            let dataInfo = [];
            dataInfo.push({ name: dataGeneral.Observation });
            let columns = [];
            let columnDefs = Object.keys(dataInfo[0]);

            for (let index = 0; index < columnDefs.length; index++) {
                columns.push({
                    dataKey: columnDefs[index]
                });
            }
            let tableWidthBody = ancho/7*6;

            doc.autoTable(columns, dataInfo, {
                startY: tablaY,
                theme: 'plain',
                columnWidth: textoY,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                
                tableWidth: tableWidthBody,
                margin: { left: 35+ancho/7 },
                styles: { 
                    cellPadding: { bottom: 2 },
                    margin: { bottom: 2 },
                    fontSize: sizeText, 
                    halign: "left", 
                    valign: "middle",
                    cellWidth: tableWidthBody,
                },
                headStyles: {
                    textColor: "#000",
                    halign: "left",
                },
            });
        }
        doc.setDrawColor("#000");
    }

    doc.setDrawColor("#000");
    cuadroY = 375;
    textoY = 382;
    cuadroAlto = 40;

    doc.rect(30, cuadroY, ancho/3, cuadroAlto); 
    doc.text(this.$t('label.transport'), 35, textoY+2, 'left'); 
    doc.text(this.$t('label.received')+': _______________________', 35, textoY+15, 'left'); 
    doc.text(this.$t('label.date')+': __________________________', 35, textoY+25, 'left'); 

    doc.rect(30+ancho/3, cuadroY, ancho/3, cuadroAlto);
    doc.text(this.$t('label.yard'), 35+ancho/3, textoY+2, 'left'); 
    doc.text(this.$t('label.received')+': _______________________', 35+ancho/3, textoY+15, 'left'); 
    doc.text(this.$t('label.date')+': __________________________', 35+ancho/3, textoY+25, 'left'); 

    doc.rect(30+ancho/3*2, cuadroY, ancho/3, cuadroAlto); 
    doc.text(this.user.CompanyName, 35+ancho/3*2, textoY+2, 'left'); 
    doc.text(this.$t('label.received')+': _______________________', 35+ancho/3*2, textoY+15, 'left'); 
    doc.text(this.$t('label.date')+': __________________________', 35+ancho/3*2, textoY+25, 'left'); 

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
    this.$store.state.visitas.apiStateForm = ENUM.INIT;
}


async function getOperationalReportExcel(rowData, titulo, excel, val) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    const hoy = new Date();

    columnsDefs = Object.keys(rowData[0]);
    keyColumns = columnsDefs;
    y = columnsDefs.length;
    bandera=false;
    
    if (excel) {

        let tituloExcel = "";
        if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
            tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
            this.dataBuque.CountryName = this.itinerarySelected.CountryName;
            this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        }

        if (y < 10) letra = 10;
        worksheet.mergeCells('A1:B2');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B2');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
        worksheet.mergeCells("C2:" + numeroLetra(y) + "2");

        const customCellCompanyBranch = worksheet.getCell("C1");

        const row1 = worksheet.getRow(1);
        row1.height = 30; 
        customCellCompanyBranch.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCellCompanyBranch.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCellCompanyBranch.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            }
        }
        customCellCompanyBranch.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
        customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                
        const row2 = worksheet.getRow(2);    
        row2.height = 30;
        const customCellTitleVessel = worksheet.getCell("C2");
        customCellTitleVessel.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCellTitleVessel.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            }
        }
        customCellTitleVessel.border = {
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCellTitleVessel.value = tituloExcel;
        customCellTitleVessel.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        

        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCellTitle = worksheet.getCell("A3");
        customCellTitle.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCellTitle.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCellTitle.value = titulo;
        customCellTitle.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / val.length);
        let Rango = [];
        let Extra = (y-(division*val.length));
        for (let index = 0 , Num = 1; index < val.length; index++, Num++) {
            if (index < (val.length-1)) {
                if (index==0) {
                    if (Extra>=division) {
                        Num++;
                        Rango.push({
                            rango1: numeroLetra(division*Num),
                            rango11: numeroLetra((division*Num) +1),
                        })
                    }else{
                        Rango.push({
                            rango1: numeroLetra(division),
                            rango11: numeroLetra((division) +1),
                        })
                    }
                }else{
                    Rango.push({
                        rango1: numeroLetra(division*Num),
                        rango11: numeroLetra((division*Num) +1),
                    })
                }    
            }else{
                Rango.push({
                    rango1: numeroLetra(y),
                })
            }
        }
        
        //rango de la filas
        worksheet.getRow(4).height = 35
        worksheet.mergeCells("A4:" + Rango[0].rango1 +"4");
        const customCellFilters = worksheet.getCell("A4");
        customCellFilters.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCellFilters.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCellFilters.value = val[0];
        customCellFilters.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        for (let index = 0; index < (Rango.length-1); index++) {
            worksheet.mergeCells(Rango[index].rango11 + "4:" + Rango[index+1].rango1 + "4");
            let Cell = worksheet.getCell(Rango[index].rango11+"4")
            Cell.height = 50;
            Cell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            Cell.value = val[index+1];
            Cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            Cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }

        //linea
        let linea = (Rango.length - 1);
        worksheet.mergeCells("A5:"+Rango[linea].rango1+"5");
        const customCellLine = worksheet.getCell("A5");
        customCellLine.height = 50;
        customCellLine.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCellLine.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    //primer cuadro
    let posi = Math.floor((y-5)/3);
    let posi1 = numeroLetra(posi+1);
    let posi2 = numeroLetra(posi+2);
    let posi3 = numeroLetra(2*posi+1);
    let posi4 = numeroLetra(2*posi+2);
    let posi5 = numeroLetra(y-4);

    //////////////////////////////////////////
    worksheet.getRow(6).height = 30;
    //fila 1 columna 1
    const customCellInfo61 = worksheet.getCell("A6");
    customCellInfo61.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo61.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo61.value = 'VSL NAME:';
    customCellInfo61.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCellInfo61.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'f2f2f2'
        }
    }

    //fila 1 columna 2
    worksheet.mergeCells("B6:" + posi5 +"6");
    const customCellInfo62 = worksheet.getCell("B6");
    customCellInfo62.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo62.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo62.value = 'TEST';
    customCellInfo62.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    //fila 1 columna 3
    worksheet.mergeCells(numeroLetra(y-3) +"6:" + numeroLetra(y-2) +"6");
    const customCellInfo63 = worksheet.getCell(numeroLetra(y-3) +"6");
    customCellInfo63.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo63.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo63.value = 'TEST';
    customCellInfo63.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    //fila 1 columna 4
    worksheet.mergeCells(numeroLetra(y-1) +"6:" + numeroLetra(y) +"6");
    const customCellInfo64 = worksheet.getCell(numeroLetra(y-1) +"6");
    customCellInfo64.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo64.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo64.value = 'TEST';
    customCellInfo64.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    //////////////////////////////////////////
    worksheet.getRow(7).height = 30;
    //fila 7 columna 1
    const customCellInfo71 = worksheet.getCell("A7");
    customCellInfo71.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo71.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo71.value = 'VSL NAME:';
    customCellInfo71.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCellInfo71.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'f2f2f2'
        }
    }

    //fila 7 columna 2
    worksheet.mergeCells("B7:" + posi5 +"7");
    const customCellInfo72 = worksheet.getCell("B7");
    customCellInfo72.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo72.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo72.value = 'TEST';
    customCellInfo72.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    //fila 7 columna 3
    worksheet.mergeCells(numeroLetra(y-3) +"7:" + numeroLetra(y-2) +"7");
    const customCellInfo73 = worksheet.getCell(numeroLetra(y-3) +"7");
    customCellInfo73.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo73.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo73.value = 'TEST';
    customCellInfo73.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    //fila 7 columna 4
    worksheet.mergeCells(numeroLetra(y-1) +"7:" + numeroLetra(y) +"7");
    const customCellInfo74 = worksheet.getCell(numeroLetra(y-1) +"7");
    customCellInfo74.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo74.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo74.value = 'TEST';
    customCellInfo74.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    ///////////////////////////////////////////
    worksheet.getRow(8).height = 30;
    //fila 8 columna 1
    const customCellInfo81 = worksheet.getCell("A8");
    customCellInfo81.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo81.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo81.value = 'SOLICITUD DE SERVICIO (SSP)';
    customCellInfo81.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCellInfo81.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'f2f2f2'
        }
    }

    //fila 8 columna 2
    worksheet.mergeCells("B8:" + posi1 +"8");
    const customCellInfo82 = worksheet.getCell("B8");
    customCellInfo82.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo82.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo82.value = 'TEST';
    customCellInfo82.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }


    //fila 8 columna 3
    worksheet.mergeCells(posi2 +"8:" + posi3 +"8");
    const customCellInfo83 = worksheet.getCell(  posi2 +"8");
    customCellInfo83.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo83.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo83.value = 'TEST';
    customCellInfo83.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    //fila 8 columna 4

    worksheet.mergeCells(posi4 +"8:" + posi5 +"8");
    const customCellInfo84 = worksheet.getCell(  posi4 +"8");
    customCellInfo84.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo84.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo84.value = 'TEST';
    customCellInfo84.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    //fila 8 columna 5
    worksheet.mergeCells(numeroLetra(y-3) +"8:" + numeroLetra(y-2) +"8");
    const customCellInfo85 = worksheet.getCell(numeroLetra(y-3) +"8");
    customCellInfo85.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo85.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo85.value = 'TEST';
    customCellInfo85.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    //fila 8 columna 6
    worksheet.mergeCells(numeroLetra(y-1) +"8:" + numeroLetra(y) +"8");
    const customCellInfo86 = worksheet.getCell(numeroLetra(y-1) +"8");
    customCellInfo86.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo86.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo86.value = 'TEST';
    customCellInfo86.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

////////////////////////////////////////////
    worksheet.getRow(9).height = 30;
    //fila 9 columna 1
    const customCellInfo91 = worksheet.getCell("A9");
    customCellInfo91.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo91.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo91.value = 'SOLICITUD DE SERVICIO (SSP)';
    customCellInfo91.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCellInfo91.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'f2f2f2'
        }
    }

    //fila 9 columna 2
    worksheet.mergeCells("B9:" + posi1 +"9");
    const customCellInfo92 = worksheet.getCell("B9");
    customCellInfo92.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo92.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo92.value = 'TEST';
    customCellInfo92.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    //fila 4 columna 3
    worksheet.mergeCells(posi2 +"9:" + posi3 +"9");
    const customCellInfo93 = worksheet.getCell(  posi2 +"9");
    customCellInfo93.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo93.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo93.value = 'TEST';
    customCellInfo93.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    //fila 9 columna 3
    worksheet.mergeCells(posi4 +"9:" + posi5 +"9");
    const customCellInfo94 = worksheet.getCell(  posi4 +"9");
    customCellInfo94.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo94.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo94.value = 'TEST';
    customCellInfo94.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }


    //fila 9 columna 5
    const customCellInfo95 = worksheet.getCell(numeroLetra(y-3) +"9");
    customCellInfo95.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo95.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo95.value = 'TEST';
    customCellInfo95.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

        //fila 9 columna 6
    const customCellInfo96 = worksheet.getCell(numeroLetra(y-2) +"9");
    customCellInfo96.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo96.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo96.value = 'TEST';
    customCellInfo96.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    //fila 9 columna 7
    const customCellInfo97 = worksheet.getCell(numeroLetra(y-1) +"9");
    customCellInfo97.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo97.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo97.value = 'TEST';
    customCellInfo97.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    //fila 9 columna 8
    const customCellInfo98 = worksheet.getCell(numeroLetra(y) +"9");
    customCellInfo98.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCellInfo98.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCellInfo98.value = 'TEST';
    customCellInfo98.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    headerRow.height = 30;
    
    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }
        
        
        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data; 
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })
    worksheet.getColumn(1).width = 30; 
    rowData.map(async function(data){
       
            let dataRow = worksheet.addRow();
            let indice = 0;
            
            keyColumns.map(function (data2) {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
        
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function finalOperationsReport() {
    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
    this.items = [];
    let rowData = [];
    
    const response1 = await this.$http.getFile('base64', {
        path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });

    await this.$http.get("VisitReportFinalOperations-by-VisitId", { VisitId: this.$store.state.visitas.VisitId })
        .then(response => {
        this.items = [...response.data.data];

        let bandera = false;
    
        let ResumeTpCargo_C1 = [];
        let LinesSummary1_C2 = [];
        let LinesSummary2_C3 = [];
        let ClientActivity_C4 = [];
        let VisitDates_C5 = [];
        let StoppedTime_C6 = [];
        let OperationsTime_C7 = [];
        let Movements_C8 = [];
        let Productivity_C9 = [];
        let CraneDetails_C10 = [];
        
        //CUADRO 1
        if( this.items.length > 0 ){
            this.items = this.items[0];

            this.items.TpCargoJson.map(async(item,index) => {
                let objeto = {};
                objeto[this.$t('label.resume')] = item.TpCargoDetailCode ? item.TpCargoDetailCode : '';
                objeto[this.$t('label.dischargeFull')] = item.DischargeFull ? item.DischargeFull : 0;
                objeto[this.$t('label.dischargeEmpty')] = item.DischargeEmpty ? item.DischargeEmpty : 0;
                objeto[this.$t('label.loadFull')] = item.LoadFull ? item.LoadFull : 0;
                objeto[this.$t('label.loadEmpty')] = item.LoadEmpty ? item.LoadEmpty : 0;
                objeto[this.$t('label.movilizedReturned')] = item.MovilizedReturned ? item.MovilizedReturned : 0;
                objeto[this.$t('label.total')] = item.Total ? item.Total : 0;
                ResumeTpCargo_C1.push(objeto);
                bandera = true;
            });
            
            if (bandera) {
                //TOTAL CUADRO 1
                this.items.TpCargoJson[0].TpCargoTotalJson.map(async(item,index) => {
                    let objeto = {};
                    objeto[this.$t('label.resume')] = this.$t('label.total');
                    objeto[this.$t('label.dischargeFull')] = item.DischargeFull ? item.DischargeFull : 0;
                    objeto[this.$t('label.dischargeEmpty')] = item.DischargeEmpty ? item.DischargeEmpty : 0;
                    objeto[this.$t('label.loadFull')] = item.LoadFull ? item.LoadFull : 0;
                    objeto[this.$t('label.loadEmpty')] = item.LoadEmpty ? item.LoadEmpty : 0;
                    objeto[this.$t('label.movilizedReturned')] = item.MovilizedReturned ? item.MovilizedReturned : 0;
                    objeto[this.$t('label.total')] = item.Total ? item.Total : 0;
                    ResumeTpCargo_C1.push(objeto);   
                });
                //TEUS CUADRO 1
                this.items.TpCargoJson[0].TpCargoTeusJson.map(async(item,index) => {
                    let objeto = {};
                    objeto[this.$t('label.resume')] = "TEUS";
                    objeto[this.$t('label.dischargeFull')] = item.DischargeFull ? item.DischargeFull : 0;
                    objeto[this.$t('label.dischargeEmpty')] = item.DischargeEmpty ? item.DischargeEmpty : 0;
                    objeto[this.$t('label.loadFull')] = item.LoadFull ? item.LoadFull : 0;
                    objeto[this.$t('label.loadEmpty')] = item.LoadEmpty ? item.LoadEmpty : 0;
                    objeto[this.$t('label.movilizedReturned')] = item.MovilizedReturned ? item.MovilizedReturned : 0;
                    objeto[this.$t('label.total')] = item.Total ? item.Total : 0;
                    ResumeTpCargo_C1.push(objeto);   
                });
                //weight CUADRO 1
                this.items.TpCargoJson[0].TpCargoWeightJson.map(async(item,index) => {
                    let objeto = {};
                    objeto[this.$t('label.resume')] = this.$t('label.weight');
                    objeto[this.$t('label.dischargeFull')] = item.DischargeFull ? item.DischargeFull : 0;
                    objeto[this.$t('label.dischargeEmpty')] = item.DischargeEmpty ? item.DischargeEmpty : 0;
                    objeto[this.$t('label.loadFull')] = item.LoadFull ? item.LoadFull : 0;
                    objeto[this.$t('label.loadEmpty')] = item.LoadEmpty ? item.LoadEmpty : 0;
                    objeto[this.$t('label.movilizedReturned')] = item.MovilizedReturned ? item.MovilizedReturned : 0;
                    objeto[this.$t('label.total')] = item.Total ? item.Total : 0;
                    ResumeTpCargo_C1.push(objeto);   
                });
            }  
            
            //CUADRO 2
            bandera=false;
            let objeto = {};
            objeto[this.$t('label.newShippingLine')] = this.$t('label.resume');
            objeto[this.$t('label.quantity')+'_DL'] = this.$t('label.dischargeFull');
            objeto['TEUS_DL'] = '';
            objeto[this.$t('label.quantity')+'_DV'] = this.$t('label.dischargeEmpty');
            objeto['TEUS_DV'] = '';
            objeto[this.$t('label.quantity')+'_ML'] = this.$t('label.mobilizedFull');
            objeto['TEUS_ML'] = '';
            objeto[this.$t('label.quantity')+'_T'] = this.$t('label.totals');
            objeto['TEUS_T'] = '';

            LinesSummary1_C2.push(objeto);   
            
            let arrtotales = [0,0,0,0,0,0,0,0];
            this.items.LinesSummary1Json.forEach(x => {
                arrtotales[0]+= x.DischargeFullCant;
                arrtotales[1]+= x.DischargeFullTeus;
                arrtotales[2]+= x.DischargeEmptyCant;
                arrtotales[3]+= x.DischargeEmptyTeus;
                arrtotales[4]+= x.MovilizedFullCant;
                arrtotales[5]+= x.MovilizedFullTeus;
                arrtotales[6]+= x.TotalCant;
                arrtotales[7]+= x.TotalTeus;

                let objeto2 = {};
                objeto2[this.$t('label.newShippingLine')] = x.ShippingLineCode ? x.ShippingLineCode : '';
                objeto2[this.$t('label.quantity')+'_DL'] = x.DischargeFullCant ? x.DischargeFullCant : 0;
                objeto2['TEUS_DL'] = x.DischargeFullTeus ? x.DischargeFullTeus : 0;
                objeto2[this.$t('label.quantity')+'_DV'] = x.DischargeEmptyCant ? x.DischargeEmptyCant : 0;
                objeto2['TEUS_DV'] = x.DischargeEmptyTeus ? x.DischargeEmptyTeus : 0;
                objeto2[this.$t('label.quantity')+'_ML'] = x.MovilizedFullCant ? x.MovilizedFullCant : 0;
                objeto2['TEUS_ML'] = x.MovilizedFullTeus ? x.MovilizedFullTeus : 0;
                objeto2[this.$t('label.quantity')+'_T'] = x.TotalCant ? x.TotalCant : 0;
                objeto2['TEUS_T'] = x.TotalTeus ? x.TotalTeus : 0;
    
                LinesSummary1_C2.push(objeto2); 
           
                bandera = true;
            });
            //TOTAL CUADRO 2
            if (bandera) {
                let objeto = {};
                objeto[this.$t('label.newShippingLine')] = this.$t('label.total');
                objeto[this.$t('label.quantity')+'_DL'] = arrtotales[0];
                objeto['TEUS_DL'] = arrtotales[1];
                objeto[this.$t('label.quantity')+'_DV'] = arrtotales[2];
                objeto['TEUS_DV'] = arrtotales[3];
                objeto[this.$t('label.quantity')+'_ML'] = arrtotales[4];
                objeto['TEUS_ML'] = arrtotales[5];
                objeto[this.$t('label.quantity')+'_T'] = arrtotales[6];
                objeto['TEUS_T'] = arrtotales[7];
    
                LinesSummary1_C2.push(objeto); 
            }
        
            //CUADRO 3
            bandera=false;

            let objeto3 = {};
            objeto3[this.$t('label.newShippingLine')] = this.$t('label.resume');
            objeto3[this.$t('label.quantity')+'_DL'] = this.$t('label.loadFull');
            objeto3['TEUS_DL'] = '';
            objeto3[this.$t('label.quantity')+'_DV'] = this.$t('label.loadEmpty');
            objeto3['TEUS_DV'] = '';
            objeto3[this.$t('label.quantity')+'_ML'] = this.$t('label.mobilizedEmpty');
            objeto3['TEUS_ML'] = '';
            objeto3[this.$t('label.quantity')+'_T'] = this.$t('label.totals');
            objeto3['TEUS_T'] = '';

            LinesSummary2_C3.push(objeto);  
            
            arrtotales = [0,0,0,0,0,0,0,0];
            this.items.LinesSummary2Json.forEach(x => {
                arrtotales[0]+= x.LoadFullCant;
                arrtotales[1]+= x.LoadFullTeus;
                arrtotales[2]+= x.LoadEmptyCant;
                arrtotales[3]+= x.LoadEmptyTeus;
                arrtotales[4]+= x.MovilizedEmptyCant;
                arrtotales[5]+= x.MovilizedEmptyTeus;
                arrtotales[6]+= x.TotalCant;
                arrtotales[7]+= x.TotalTeus;

                let objeto3 = {};
                objeto3[this.$t('label.newShippingLine')] = x.ShippingLineCode ? x.ShippingLineCode : '';
                objeto3[this.$t('label.quantity')+'_DL'] = x.LoadFullCant ? x.LoadFullCant : 0;
                objeto3['TEUS_DL'] = x.LoadFullTeus ? x.LoadFullTeus : 0;
                objeto3[this.$t('label.quantity')+'_DV'] = x.LoadEmptyCant ? x.LoadEmptyCant : 0;
                objeto3['TEUS_DV'] = x.LoadEmptyTeus ? x.LoadEmptyTeus : 0;
                objeto3[this.$t('label.quantity')+'_ML'] = x.MovilizedEmptyCant ? x.MovilizedEmptyCant : 0;
                objeto3['TEUS_ML'] = x.MovilizedEmptyTeus ? x.MovilizedEmptyTeus : 0;
                objeto3[this.$t('label.quantity')+'_T'] = x.TotalCant ? x.TotalCant : 0;
                objeto3['TEUS_T'] = x.TotalTeus ? x.TotalTeus : 0;
    
                LinesSummary2_C3.push(objeto3); 

                bandera = true;
            });
            //TOTAL CUADRO 3
            if (bandera) {
                let objeto = {};
                objeto[this.$t('label.newShippingLine')] = this.$t('label.total');
                objeto[this.$t('label.quantity')+'_DL'] = arrtotales[0];
                objeto['TEUS_DL'] = arrtotales[1];
                objeto[this.$t('label.quantity')+'_DV'] = arrtotales[2];
                objeto['TEUS_DV'] = arrtotales[3];
                objeto[this.$t('label.quantity')+'_ML'] = arrtotales[4];
                objeto['TEUS_ML'] = arrtotales[5];
                objeto[this.$t('label.quantity')+'_T'] = arrtotales[6];
                objeto['TEUS_T'] = arrtotales[7];
    
                LinesSummary2_C3.push(objeto); 
            }

            //CUADRO 4
            this.items.ClientActivityJson.forEach(x => {
                ClientActivity_C4.push({
                    Agency: x.Agency ? x.Agency : '',
                    WarehouseRecipient: x.WarehouseRecipient ? x.WarehouseRecipient : '',
                    PortOperator: x.PortOperator ? x.PortOperator : '',
                    Stevedore: x.Stevedore ? x.Stevedore : '',
                    HumanResources: x.HumanResources ? x.HumanResources : '',
                    Wincher: x.Wincher ? x.Wincher : '',
                    Security: x.Security ? x.Security : '',
                    Transport: x.Transport ? x.Transport : '',
                    Machinery: x.Machinery ? x.Machinery : '',
                    Equipment: x.Equipment ? x.Equipment : '',
                    Forklift: x.Forklift ? x.Forklift : '',
                    LiftingEquipment: x.LiftingEquipment ? x.LiftingEquipment : '',
                });
            });
            //CUADRO 5
            this.items.VisitDatesJson.map(async(item,index) => {
                VisitDates_C5.push({
                    ArrivalDate: item.ArrivalDate ? DateFormater.formatDateTimeWithSlash(item.ArrivalDate) : '',
                    BerthDate: item.BerthDate ? DateFormater.formatDateTimeWithSlash(item.BerthDate) : '',
                    BeginningOperations: item.BeginningOperationsDate ? DateFormater.formatDateTimeWithSlash(item.BeginningOperationsDate) : '',
                    EndOperations: item.EndOperationsDate ? DateFormater.formatDateTimeWithSlash(item.EndOperationsDate) : '',
                    DepartureDate: item.DepartureDate ? DateFormater.formatDateTimeWithSlash(item.DepartureDate) : '',
                    OperationsTime: item.OperationsTime ? item.OperationsTime : 0,
                    BerthName: item.BerthName ? item.BerthName : '',
                    Origin: item.Origin ? item.Origin : '',
                    Destination: item.Destination ? item.Destination : '',
                    VesselImo: item.VesselImo ? item.VesselImo : '',
                    VesselFlag: item.VesselFlag ? item.VesselFlag : '',
                });
            });
            //CUADRO 6 
            this.items.StoppedTimeJson.map(async(item,index) => {
                let objeto6 = {};
                objeto6[this.$t('label.timeStopped')] = item.CraneAlias ? item.CraneAlias : '';
                objeto6[''] = item.CraneAlias ? item.CraneAlias : '';
                objeto6[this.$t('label.hours')] = item.CraneHours ? item.CraneHours : '';
                StoppedTime_C6.push(objeto6);
            });

            /*****************CUADRO 7: OPERATIONS TIME**********************/
            this.items.OperationsTimeJson.forEach(x => {
                OperationsTime_C7.push({
                    NetOperationsTime: x.NetOperationsTime ? x.NetOperationsTime : 0,
                    TotalOperationsTime: x.TotalOperationsTime ? x.TotalOperationsTime : 0,
                });
            });

            /*****************CUADRO 8: MOVEMENTS**********************/
            let objeto8 = [];
            this.items.MovementsJson.map(async(item,index) => {
                objeto8.push({
                    MOVEMENTS: this.$t('label.dischargeFull'),
                    QUANTITY: (item.DischargeJson.length>0) ? item.DischargeJson[0].FullJson[0].Cant : 0,
                    QUANTITY_TEUS: (item.DischargeJson.length>0) ? item.DischargeJson[0].FullJson[0].Teus : 0,
                    QUANTITY_TONS: (item.DischargeJson.length>0) ? item.DischargeJson[0].FullJson[0].Ton : 0
                });

                objeto8.push({
                    MOVEMENTS: this.$t('label.dischargeEmpty'),
                    QUANTITY: (item.DischargeJson.length>0) ? item.DischargeJson[0].EmptyJson[0].Cant : 0,
                    QUANTITY_TEUS: (item.DischargeJson.length>0) ? item.DischargeJson[0].EmptyJson[0].Teus : 0,
                    QUANTITY_TONS: (item.DischargeJson.length>0) ? item.DischargeJson[0].EmptyJson[0].Ton : 0
                });

                objeto8.push({
                    MOVEMENTS: this.$t('label.loadFull'),
                    QUANTITY: (item.LoadJson.length>0) ? item.LoadJson[0].FullJson[0].Cant : 0,
                    QUANTITY_TEUS: (item.LoadJson.length>0) ? item.LoadJson[0].FullJson[0].Teus : 0,
                    QUANTITY_TONS: (item.LoadJson.length>0) ? item.LoadJson[0].FullJson[0].Ton : 0
                });

                objeto8.push({
                    MOVEMENTS: this.$t('label.loadEmpty'),
                    QUANTITY: (item.LoadJson.length>0) ? item.LoadJson[0].EmptyJson[0].Cant : 0,
                    QUANTITY_TEUS: (item.LoadJson.length>0) ? item.LoadJson[0].EmptyJson[0].Teus : 0,
                    QUANTITY_TONS: (item.LoadJson.length>0) ? item.LoadJson[0].EmptyJson[0].Ton : 0
                });

                objeto8.push({
                    MOVEMENTS: this.$t('label.mobilized')+' '+this.$t('label.full'),
                    QUANTITY: (item.RestowJson.length>0) ? item.RestowJson[0].FullJson[0].Cant : 0,
                    QUANTITY_TEUS: (item.RestowJson.length>0) ? item.RestowJson[0].FullJson[0].Teus : 0,
                    QUANTITY_TONS: (item.RestowJson.length>0) ? item.RestowJson[0].FullJson[0].Ton : 0
                });

                objeto8.push({
                    MOVEMENTS: this.$t('label.mobilized')+' '+this.$t('label.empty'),
                    QUANTITY: (item.RestowJson.length>0) ? item.RestowJson[0].EmptyJson[0].Empty : 0,
                    QUANTITY_TEUS: (item.RestowJson.length>0) ? item.RestowJson[0].EmptyJson[0].Teus : 0,
                    QUANTITY_TONS: (item.RestowJson.length>0) ? item.RestowJson[0].EmptyJson[0].Ton : 0
                });

                objeto8.push({
                    MOVEMENTS: 'RETORNO CRB-MIT-CTG'+' '+this.$t('label.full'),
                    QUANTITY: (item.CancelJson.length>0) ? item.CancelJson[0].FullJson[0].Cant : 0,
                    QUANTITY_TEUS: (item.CancelJson.length>0) ? item.CancelJson[0].FullJson[0].Teus : 0,
                    QUANTITY_TONS: (item.CancelJson.length>0) ? item.CancelJson[0].FullJson[0].Ton : 0
                });

                objeto8.push({
                    MOVEMENTS: 'RETORNO CRB-MIT-CTG'+' '+this.$t('label.empty'),
                    QUANTITY: (item.CancelJson.length>0) ? item.CancelJson[0].EmptyJson[0].Cant : 0,
                    QUANTITY_TEUS: (item.CancelJson.length>0) ? item.CancelJson[0].EmptyJson[0].Teus : 0,
                    QUANTITY_TONS: (item.CancelJson.length>0) ? item.CancelJson[0].EmptyJson[0].Ton : 0
                });

                objeto8.push({
                    MOVEMENTS: this.$t('label.twinBoxes'),
                    QUANTITY: item.TwinBox ? item.TwinBox : 0,
                    QUANTITY_TEUS: 0,
                    QUANTITY_TONS: 0
                });

                objeto8.push({
                    MOVEMENTS: this.$t('label.hatchCovers'),
                    QUANTITY: item.HatchCover ? item.HatchCover : 0,
                    QUANTITY_TEUS: 0,
                    QUANTITY_TONS: 0
                });

                objeto8.push({
                    MOVEMENTS: this.$t('label.totals'),
                    QUANTITY: item.Total ? item.Total : 0,
                    QUANTITY_TEUS: objeto8[0].QUANTITY_TEUS + objeto8[1].QUANTITY_TEUS + objeto8[2].QUANTITY_TEUS + objeto8[3].QUANTITY_TEUS,
                    QUANTITY_TONS: objeto8[0].QUANTITY_TONS + objeto8[1].QUANTITY_TONS + objeto8[2].QUANTITY_TONS + objeto8[3].QUANTITY_TONS,
                });

            });

            objeto8.map(async(item,index) => {
                let objeto = {};
                objeto[this.$t('label.movements')] = item.MOVEMENTS;
                objeto[''] = item.MOVEMENTS;
                objeto[this.$t('label.quantity')] = item.QUANTITY;
                objeto['TEUS'] = item.QUANTITY_TEUS;
                objeto['TONS'] = item.QUANTITY_TONS;
                Movements_C8.push(objeto);
            });

            /*****************CUADRO 9: PRODUCTIVITY TIME**********************/
            this.items.ProductivityJson.forEach(x => {
                Productivity_C9.push({
                    ProductivityGross: x.ProductivityGross ? x.ProductivityGross : 0,
                    ProductivityNet: x.ProductivityNet ? x.ProductivityNet : 0,
                    ProductivityBerth: x.ProductivityBerth ? x.ProductivityBerth : 0,
                });
            });

            /*****************CUADRO 10: CRANE **********************/
            
            for(let k = 0; k < 2; k++){ 
                CraneDetails_C10.push({
                    CraneAlias : this.$t('label.details'),
                    BeginDischargeDate : this.$t('label.beginningDischarge'),
                    EndDischargeDate: this.$t('label.finishDischarge'),
                    BeginLoadingDate : this.$t('label.beginningLoad'),
                    EndOperationsDate : this.$t('label.finishLoad'),
                    CraneTime : this.$t('label.times'),
                    DelayTime : this.$t('label.lostTime'),
                    NetTime : this.$t('label.netTime'),
                    Discharged : this.$t('label.discharge'),
                    Movilized : this.$t('label.mobilized'),
                    Loaded : this.$t('label.LOAD'),
                    HatchCoverTwinBox : this.$t('label.hatchCovers')+' + '+this.$t('label.twinsBoxes'),
                    Total : this.$t('label.total'),
                });
            }

            this.items.CraneDetailsJson.forEach(x => {
                CraneDetails_C10.push({
                    CraneAlias : x.CraneAlias ? x.CraneAlias : '',
                    BeginDischargeDate : x.BeginDischargeDate ? DateFormater.formatDateTimeWithSlash(x.BeginDischargeDate) : '',
                    EndDischargeDate: x.EndDischargeDate ? DateFormater.formatDateTimeWithSlash(x.EndDischargeDate) : '',
                    BeginLoadingDate : x.BeginLoadingDate ? DateFormater.formatDateTimeWithSlash(x.BeginLoadingDate) : '',
                    EndOperationsDate : x.EndOperationsDate ? DateFormater.formatDateTimeWithSlash(x.EndOperationsDate) : '',
                    CraneTime : x.CraneTime ? x.CraneTime : '',
                    DelayTime : x.DelayTime ? x.DelayTime : '',
                    NetTime : x.NetTime ? x.NetTime : '',
                    Discharged : x.Discharged ? x.Discharged : 0,
                    Movilized : x.Movilized ? x.Movilized : 0,
                    Loaded : x.Loaded ? x.Loaded : 0,
                    HatchCoverTwinBox : x.HatchCoverTwinBox ? x.HatchCoverTwinBox : 0,
                    Total : (x.Discharged ? x.Discharged : 0) + (x.Movilized ? x.Movilized : 0) + (x.Loaded ? x.Loaded : 0) + (x.HatchCoverTwinBox ? x.HatchCoverTwinBox : 0),
                });
            });
        }

      rowData = [  
      {
        ResumeTpCargo_C1: ResumeTpCargo_C1,
        LinesSummary1_C2: LinesSummary1_C2,
        LinesSummary2_C3: LinesSummary2_C3,
        ClientActivity_C4: ClientActivity_C4,
        VisitDates_C5: VisitDates_C5,
        StoppedTime_C6: StoppedTime_C6,
        OperationsTime_C7: OperationsTime_C7,
        Movements_C8: Movements_C8,
        Productivity_C9: Productivity_C9,
        CraneDetails_C10: CraneDetails_C10,
    }];

    let titulo = this.$t('label.finalOperationsReport');

    let _lang = this.$i18n.locale;
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    //INFO BUQUE
    let tituloExcel = "";
    
    tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+
    this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+
    DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+
    DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;

    let y = 0;
    let letra = 11;
    let columnsDefs = [];
    let keyColumns = [];
    bandera = true;

    columnsDefs = Object.keys(rowData[0].ResumeTpCargo_C1[0]);
    keyColumns = columnsDefs;
    y = 15;
    bandera = false;

    if (y < 10) letra = 8;
    worksheet.mergeCells('A1:A2');
    

    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
    const imageId = workbook.addImage({
        base64: myBase64Image,
        extension: divisiones1[1],
    });
    worksheet.addImage(imageId, 'A1:A2');

    const rowIndex1 = worksheet.getRow(1);
    rowIndex1.height = 100;

    //worksheet.mergeCells("B1:" + numeroLetra(y) + "1"); XXXD
    worksheet.mergeCells("B1:I1");

    const customCellCompanyBranch = worksheet.getCell("B1");

    const row1 = worksheet.getRow(1);
    row1.height = 30;
    customCellCompanyBranch.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCellCompanyBranch.note = {
        margins: {
            insetmode: 'auto',
            inset: [10, 10, 5, 5]
        }
    }
    customCellCompanyBranch.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'f2f2f2'
        }
    }
    customCellCompanyBranch.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
    customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells("B2:I2");
    const customCell = worksheet.getCell("B2");
    const row2 = worksheet.getRow(2);
    row2.height = 30;
    customCell.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell.note = {
        margins: {
            insetmode: 'auto',
            inset: [10, 10, 5, 5]
        }
    }
    customCell.border = {
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'f2f2f2'
        }
    }
    customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }    
    customCell.value = tituloExcel;

    worksheet.mergeCells("A3:I3");
    const customCell2 = worksheet.getCell("A3");
    customCell2.height = 30;
    customCell2.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell2.value = titulo;
    customCell2.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    }

   
    //print date
    const hoy = new Date();
   
    //Benning Operation
    worksheet.mergeCells("A4:C4");
    const customCellBenningOperation = worksheet.getCell("A4");
    customCellBenningOperation.height = 30;
    customCellBenningOperation.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCellBenningOperation.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    customCellBenningOperation.value = this.$t('label.benningOperation')+': '+((rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].BeginningOperations : '');
    customCellBenningOperation.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    worksheet.mergeCells("D4:E4");
    const customCellEndOperation = worksheet.getCell("D4");
    customCellEndOperation.height = 30;
    customCellEndOperation.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCellEndOperation.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    customCellEndOperation.value = this.$t('label.endOperation')+': '+( (rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].EndOperations : '');
    customCellEndOperation.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }

   //printed
    worksheet.mergeCells("F4:G4");
    const customCellPrinted = worksheet.getCell("F4");
    customCellPrinted.height = 30;
    customCellPrinted.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCellPrinted.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    customCellPrinted.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy);//hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
    customCellPrinted.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    //usuario
    worksheet.mergeCells("H4:I4");
    const customCellUser = worksheet.getCell("H4");
    customCellUser.height = 50;
    customCellUser.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCellUser.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    customCellUser.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
    customCellUser.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    }


    //linea
    worksheet.mergeCells("A5:I5");
    const customCell8 = worksheet.getCell("A5");
    customCell8.height = 50;
    customCell8.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

    var headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    /****************************************************************/
    /***********************CUADRO 1: RESUMEN ***********************/
    /****************************************************************/
    let i = 0;
    columnsDefs.map(function (data) {
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
            long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        worksheet.getColumn(i + 1).width = long;
        let cell = headerRow.getCell(i + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '262626'
            }
        }
        cell.value = data.replace("_"," ");
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        i++;
    })

    rowData[0].ResumeTpCargo_C1.map(async function (data) {
      let dataRow = worksheet.addRow();
      let indice = 0;
      let bandera=false;

      keyColumns.map(function (data2) {
          let cell = dataRow.getCell(indice + 1);
          cell.value = data[data2];
          
          if ((cell.value=='TOTAL') || (cell.value=="TEUS") || (cell.value=='PESO') || (cell.value=='WEIGHT')) bandera = true;

          if (bandera) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                },
            }
          }
          if (indice!=0 && !isNaN(data[data2]) && (cell.col>1 && cell.col<8 && (cell.row == 14 || cell.row == 15))) {
            cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
          }
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
          indice++;
      })
    })

    /****************************************************************/
    /*****************CUADRO 2: SUMARIO LINEAS 1*********************/
    /****************************************************************/
    worksheet.addRow();
    let data = rowData[0].LinesSummary1_C2[0];

    //TITULO COMBINADO
    if (data.LINEA_NAVIERA == "RESUMEN" || data.LINEA_NAVIERA == "RESUME" || data.SHIPPING_LINE == "RESUMEN" || data.SHIPPING_LINE == "RESUME")  {
      let lineaActual = rowData[0].ResumeTpCargo_C1.length + 8;
      //Grupo 1
      let cellA4 = worksheet.getCell("A"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.SHIPPING_LINE : data.LINEA_NAVIERA;
      //Grupo 2
      worksheet.mergeCells("B"+lineaActual+":C"+lineaActual);
      cellA4 = worksheet.getCell("C"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.QUANTITY_DL : data.CANTIDAD_DL;
      //Grupo 3
      worksheet.mergeCells("D"+lineaActual+":E"+lineaActual);
      cellA4 = worksheet.getCell("D"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.QUANTITY_DV : data.CANTIDAD_DV;
      //Grupo 4
      worksheet.mergeCells("F"+lineaActual+":G"+lineaActual);
      cellA4 = worksheet.getCell("F"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.QUANTITY_ML : data.CANTIDAD_ML;
      //Grupo 5
      worksheet.mergeCells("H"+lineaActual+":I"+lineaActual);
      cellA4 = worksheet.getCell("I"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.QUANTITY_T : data.CANTIDAD_T;
    }
    
    headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    //headerRow.height = 50;

    columnsDefs = Object.keys(rowData[0].LinesSummary1_C2[1]);
    keyColumns = columnsDefs;
    i = 0;
    let spliteo = [];
    
    columnsDefs.map(function (data) {
        let valor = bandera ? data.length : data.length;
        
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
          long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }
    
        worksheet.getColumn(i + 1).width = long;
        let cell = headerRow.getCell(i + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '262626'
            }
        }
        
        spliteo = data.split("_");
        cell.value = spliteo[0];
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        i++;
    })
    
    rowData[0].LinesSummary1_C2.map(async function (data) {

      if ( data.SHIPPING_LINE != "RESUME" && data.LINEA_NAVIERA != "RESUMEN" ) {

          let dataRow = worksheet.addRow();
          let indice = 0;
          let bandera=false;
          keyColumns.map(function (data2) {
              let cell = dataRow.getCell(indice + 1);
              cell.value = data[data2];
              
              if (cell.value=="TOTAL") bandera = true;

              if (bandera) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '262626'
                    }
                }
                cell.font = {
                    color: {
                      argb: 'ffffff'
                    }
                }
              }
              if (indice!=0 && !isNaN(data[data2]) && (cell.col == 3 || cell.col == 5 || cell.col == 7 || cell.col == 9)) {
                cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
              }
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
              cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
              indice++;
          })
      }
    })

    /****************************************************************/
    /*****************CUADRO 3: SUMARIO LINEAS 2*********************/
    /****************************************************************/
    worksheet.addRow();
    worksheet.addRow();
    data = rowData[0].LinesSummary2_C3[0];
    //TITULO COMBINADO
    if ( data.SHIPPING_LINE == "RESUME" || data.LINEA_NAVIERA == "RESUMEN" ) {
      let lineaActual = rowData[0].ResumeTpCargo_C1.length + rowData[0].LinesSummary1_C2.length + 10;
      //Grupo 1
      let cellA4 = worksheet.getCell("A"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.SHIPPING_LINE : data.LINEA_NAVIERA;
      //Grupo 2
      worksheet.mergeCells("B"+lineaActual+":C"+lineaActual);
      cellA4 = worksheet.getCell("C"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.QUANTITY_DL : data.CANTIDAD_DL;
      //Grupo 3
      worksheet.mergeCells("D"+lineaActual+":E"+lineaActual);
      cellA4 = worksheet.getCell("D"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.QUANTITY_DV : data.CANTIDAD_DV;
      //Grupo 4
      worksheet.mergeCells("F"+lineaActual+":G"+lineaActual);
      cellA4 = worksheet.getCell("F"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.QUANTITY_ML : data.CANTIDAD_ML;
      //Grupo 5
      worksheet.mergeCells("H"+lineaActual+":I"+lineaActual);
      cellA4 = worksheet.getCell("I"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = _lang=='en' ? data.QUANTITY_T : data.CANTIDAD_T;
    }

    headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    //headerRow.height = 50;

    columnsDefs = Object.keys(rowData[0].LinesSummary2_C3[1]);
    keyColumns = columnsDefs;
    i = 0;
    spliteo = [];
    
    columnsDefs.map(function (data) {
        let valor = bandera ? data.length : data.length;
        
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
            long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        
        worksheet.getColumn(i + 1).width = long;
        let cell = headerRow.getCell(i + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '262626'
            }
        }
        
        spliteo = data.split("_");
        cell.value = spliteo[0];
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        i++;
    })
    
    rowData[0].LinesSummary2_C3.map(async function (data) {
      if ( data.SHIPPING_LINE == "RESUME" || data.LINEA_NAVIERA == "RESUMEN") {
          /*//Grupo 1
          worksheet.mergeCells("C4:F4");
          let cellA4 = worksheet.getCell("D4");
          cellA4.alignment = { vertical: 'middle', horizontal: 'center' }
          cellA4.font = {
              name: "Calibri",
              family: 4,
              underline: false,
              bold: true
          };
          cellA4.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                  argb: 'cafbf3'
              }
          }
          cellA4.value = data.Cont20FtDeck;
          //Grupo 2
          worksheet.mergeCells("G4:J4");
          cellA4 = worksheet.getCell("H4");
          cellA4.alignment = { vertical: 'middle', horizontal: 'center' }
          cellA4.font = {
              name: "Calibri",
              family: 4,
              underline: false,
              bold: true
          };
          cellA4.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                  argb: 'f9b115'
              }
          }
          cellA4.value = data.Cont20FtHold;
          //Grupo 3
          worksheet.mergeCells("K4:N4");
          cellA4 = worksheet.getCell("L4");
          cellA4.alignment = { vertical: 'middle', horizontal: 'center' }
          cellA4.font = {
              name: "Calibri",
              family: 4,
              underline: false,
              bold: true
          };
          cellA4.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                  argb: 'cccccc'
              }
          }
          cellA4.value = data.Cont20FtTotal;*/
      } else {
          let dataRow = worksheet.addRow();
          let indice = 0;
          let bandera=false;
          keyColumns.map(function (data2) {
              let cell = dataRow.getCell(indice + 1);
              cell.value = data[data2];
              if (cell.value=="TOTAL") bandera = true;

              if (bandera) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '262626'
                    },
                    color: {
                      argb: 'ffffff'
                    }
                }
                cell.font = {
                    color: {
                      argb: 'ffffff'
                    }
                }
              }
              if (indice!=0 && !isNaN(data[data2]) && (cell.col == 3 || cell.col == 5 || cell.col == 7 || cell.col == 9)) {
                cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
              }
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
              cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
              indice++;
          })
      }
    })

    /****************************************************************/
    /*****************CUADRO 4: ACTIVIDADES CLIENTE******************/
    /****************************************************************/
    let lineaActual_C4 = rowData[0].ResumeTpCargo_C1.length + rowData[0].LinesSummary1_C2.length + rowData[0].LinesSummary2_C3.length + 12;

    //*******************Agency***********************//
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellClientActivityShipping = worksheet.getCell("A"+lineaActual_C4);

    cellClientActivityShipping.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellClientActivityShipping.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellClientActivityShipping.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellClientActivityShipping.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellClientActivityShipping.value = this.$t('label.Agency')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellClientActivityShippingValue = worksheet.getCell("C"+lineaActual_C4);

    cellClientActivityShippingValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellClientActivityShippingValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellClientActivityShippingValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellClientActivityShippingValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellClientActivityShippingValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].Agency : '';

    //*******************WarehouseRecipient***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellReceivingWarehouse = worksheet.getCell("A"+lineaActual_C4);

    cellReceivingWarehouse.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellReceivingWarehouse.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellReceivingWarehouse.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellReceivingWarehouse.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellReceivingWarehouse.value = this.$t('label.receivingWarehouse')+': ';
    
    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellReceivingWarehouseValue = worksheet.getCell("C"+lineaActual_C4);

    cellReceivingWarehouseValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellReceivingWarehouseValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellReceivingWarehouseValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellReceivingWarehouseValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellReceivingWarehouseValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].WarehouseRecipient : '';

    //*******************PortOperator***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellPortOperator = worksheet.getCell("A"+lineaActual_C4);

    cellPortOperator.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellPortOperator.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellPortOperator.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellPortOperator.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellPortOperator.value = this.$t('label.portOperator')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellPortOperatorValue = worksheet.getCell("C"+lineaActual_C4);

    cellPortOperatorValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellPortOperatorValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellPortOperatorValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellPortOperatorValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellPortOperatorValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].PortOperator : '';

    //*******************Stevedore***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellStevedore = worksheet.getCell("A"+lineaActual_C4);

    cellStevedore.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellStevedore.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellStevedore.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellStevedore.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellStevedore.value = this.$t('label.stevedore')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellStevedoreValue = worksheet.getCell("C"+lineaActual_C4);

    cellStevedoreValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellStevedoreValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellStevedoreValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellStevedoreValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellStevedoreValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].Stevedore : '';

    //*******************HumanResources***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellHumanResources = worksheet.getCell("A"+lineaActual_C4);

    cellHumanResources.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellHumanResources.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellHumanResources.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellHumanResources.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellHumanResources.value = this.$t('label.humanResources')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellHumanResourcesValue = worksheet.getCell("C"+lineaActual_C4);

    cellHumanResourcesValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellHumanResourcesValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellHumanResourcesValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellHumanResourcesValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellHumanResourcesValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].HumanResources : '';

    //*******************Wincher***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellWincher = worksheet.getCell("A"+lineaActual_C4);

    cellWincher.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellWincher.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellWincher.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellWincher.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellWincher.value = this.$t('label.wincher')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellWincherValue = worksheet.getCell("C"+lineaActual_C4);

    cellWincherValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellWincherValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellWincherValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellWincherValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellWincherValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].Wincher : '';

    //*******************Security***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellSecurity = worksheet.getCell("A"+lineaActual_C4);

    cellSecurity.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    }
    cellSecurity.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellSecurity.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellSecurity.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellSecurity.value = this.$t('label.security')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellSecurityValue = worksheet.getCell("C"+lineaActual_C4);

    cellSecurityValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellSecurityValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellSecurityValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellSecurityValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellSecurityValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].Security : '';

    //*******************Transport***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellTransport = worksheet.getCell("A"+lineaActual_C4);

    cellTransport.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    }
    cellTransport.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellTransport.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellTransport.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellTransport.value = this.$t('label.transport')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellTransportValue = worksheet.getCell("C"+lineaActual_C4);

    cellTransportValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellTransportValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellTransportValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellTransportValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellTransportValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].Transport : '';

    //*******************Machinery***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellMachineries = worksheet.getCell("A"+lineaActual_C4);

    cellMachineries.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    }
    cellMachineries.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellMachineries.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellMachineries.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellMachineries.value = this.$t('label.machineries')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellMachineriesValue = worksheet.getCell("C"+lineaActual_C4);

    cellMachineriesValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellMachineriesValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellMachineriesValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellMachineriesValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellMachineriesValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].Machinery : '';

    //*******************Equipment***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellEquipment = worksheet.getCell("A"+lineaActual_C4);

    cellEquipment.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    }
    cellEquipment.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellEquipment.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellEquipment.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellEquipment.value = this.$t('label.equipment')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellEquipmentValue = worksheet.getCell("C"+lineaActual_C4);

    cellEquipmentValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellEquipmentValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellEquipmentValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellEquipmentValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellEquipmentValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].Equipment : '';

    //*******************Forklift***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellForklift = worksheet.getCell("A"+lineaActual_C4);

    cellForklift.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    }
    cellForklift.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellForklift.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellForklift.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellForklift.value = this.$t('label.forklift')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellForkliftValue = worksheet.getCell("C"+lineaActual_C4);

    cellForkliftValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellForkliftValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellForkliftValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellForkliftValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellForkliftValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].Forklift : '';

    //*******************LiftingEquipment***********************//
    lineaActual_C4++;
    worksheet.mergeCells("A"+lineaActual_C4+":B"+lineaActual_C4);
    let cellLiftingEquipment = worksheet.getCell("A"+lineaActual_C4);

    cellLiftingEquipment.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    }
    cellLiftingEquipment.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellLiftingEquipment.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellLiftingEquipment.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellLiftingEquipment.value = this.$t('label.liftingEquipment')+': ';

    worksheet.mergeCells("C"+lineaActual_C4+":G"+lineaActual_C4);
    let cellLiftingEquipmentValue = worksheet.getCell("C"+lineaActual_C4);

    cellLiftingEquipmentValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellLiftingEquipmentValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellLiftingEquipmentValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellLiftingEquipmentValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellLiftingEquipmentValue.value = (rowData[0].ClientActivity_C4.length>0) ? rowData[0].ClientActivity_C4[0].LiftingEquipment : '';

    /****************************************************************/
    /*****************CUADRO 5: FECHAS DE LA VISITA******************/
    /****************************************************************/
    let lineaActual_C5 = lineaActual_C4 + 2;
    //*******************ArrivalDate***********************//
    worksheet.mergeCells("A"+lineaActual_C5+":B"+lineaActual_C5);
    let cellArrivalDate = worksheet.getCell("A"+lineaActual_C5);

    cellArrivalDate.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellArrivalDate.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellArrivalDate.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellArrivalDate.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellArrivalDate.value = this.$t('label.dateTimeArrival')+': ';

    worksheet.mergeCells("C"+lineaActual_C5+":G"+lineaActual_C5);
    let cellArrivalDateValue = worksheet.getCell("C"+lineaActual_C5);

    cellArrivalDateValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellArrivalDateValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellArrivalDateValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellArrivalDateValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellArrivalDateValue.value = (rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].ArrivalDate : '';

    //*******************BerthDate***********************//
    lineaActual_C5++;
    worksheet.mergeCells("A"+lineaActual_C5+":B"+lineaActual_C5);
    let cellBerthDate = worksheet.getCell("A"+lineaActual_C5);

    cellBerthDate.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellBerthDate.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellBerthDate.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellBerthDate.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellBerthDate.value = this.$t('label.dateTimeBerthDate')+': ';

    worksheet.mergeCells("C"+lineaActual_C5+":G"+lineaActual_C5);
    let cellBerthDateValue = worksheet.getCell("C"+lineaActual_C5);

    cellBerthDateValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellBerthDateValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellBerthDateValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellBerthDateValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellBerthDateValue.value = (rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].BerthDate : '';

    //*******************BeginningOperations***********************//
    lineaActual_C5++;
    worksheet.mergeCells("A"+lineaActual_C5+":B"+lineaActual_C5);
    let cellBeginningOperations = worksheet.getCell("A"+lineaActual_C5);

    cellBeginningOperations.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellBeginningOperations.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellBeginningOperations.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellBeginningOperations.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellBeginningOperations.value = this.$t('label.dateTimeBeginningOperations')+': ';

    worksheet.mergeCells("C"+lineaActual_C5+":G"+lineaActual_C5);
    let cellBeginningOperationsValue = worksheet.getCell("C"+lineaActual_C5);

    cellBeginningOperationsValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellBeginningOperationsValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellBeginningOperationsValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellBeginningOperationsValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellBeginningOperationsValue.value = (rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].BeginningOperations : '';

    //*******************EndOperations***********************//
    lineaActual_C5++;
    worksheet.mergeCells("A"+lineaActual_C5+":B"+lineaActual_C5);
    let cellEndOperations = worksheet.getCell("A"+lineaActual_C5);

    cellEndOperations.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellEndOperations.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellEndOperations.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellEndOperations.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellEndOperations.value = this.$t('label.dateTimeEndOperations')+': ';

    worksheet.mergeCells("C"+lineaActual_C5+":G"+lineaActual_C5);
    let cellEndOperationsValue = worksheet.getCell("C"+lineaActual_C5);

    cellEndOperationsValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellEndOperationsValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellEndOperationsValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellEndOperationsValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellEndOperationsValue.value = (rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].EndOperations : '';

    //*******************DepartureDate***********************//
    lineaActual_C5++;
    worksheet.mergeCells("A"+lineaActual_C5+":B"+lineaActual_C5);
    let cellDepartureDate = worksheet.getCell("A"+lineaActual_C5);

    cellDepartureDate.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellDepartureDate.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellDepartureDate.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellDepartureDate.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellDepartureDate.value = this.$t('label.dateTimeDeparture')+': ';

    worksheet.mergeCells("C"+lineaActual_C5+":G"+lineaActual_C5);
    let cellDepartureDateValue = worksheet.getCell("C"+lineaActual_C5);

    cellDepartureDateValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellDepartureDateValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellDepartureDateValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellDepartureDateValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellDepartureDateValue.value = (rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].DepartureDate : '';

    //*******************OperationsTime***********************//
    lineaActual_C5++;
    worksheet.mergeCells("A"+lineaActual_C5+":B"+lineaActual_C5);
    let cellOperationsTime = worksheet.getCell("A"+lineaActual_C5);

    cellOperationsTime.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellOperationsTime.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellOperationsTime.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellOperationsTime.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellOperationsTime.value = this.$t('label.timeOperations')+': ';

    worksheet.mergeCells("C"+lineaActual_C5+":G"+lineaActual_C5);
    let cellOperationsTimeValue = worksheet.getCell("C"+lineaActual_C5);

    cellOperationsTimeValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellOperationsTimeValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellOperationsTimeValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellOperationsTimeValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellOperationsTimeValue.value = (rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].OperationsTime : '';

    //*******************BerthName***********************//
    lineaActual_C5++;
    worksheet.mergeCells("A"+lineaActual_C5+":B"+lineaActual_C5);
    let cellBerthName = worksheet.getCell("A"+lineaActual_C5);

    cellBerthName.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    }
    cellBerthName.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellBerthName.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellBerthName.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellBerthName.value = this.$t('label.assignedBerthNro')+': ';

    worksheet.mergeCells("C"+lineaActual_C5+":G"+lineaActual_C5);
    let cellBerthNameValue = worksheet.getCell("C"+lineaActual_C5);

    cellBerthNameValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellBerthNameValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellBerthNameValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellBerthNameValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellBerthNameValue.value = (rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].BerthName : '';

    //*******************OriginDestination***********************//
    lineaActual_C5++;
    worksheet.mergeCells("A"+lineaActual_C5+":G"+lineaActual_C5);
    let cellOriginDestination = worksheet.getCell("A"+lineaActual_C5);

    cellOriginDestination.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    }
    cellOriginDestination.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellOriginDestination.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellOriginDestination.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    cellOriginDestination.value =   this.$t('label.Origin')+': '+
                                    ((rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].Origin : '') +' / '+
                                    this.$t('label.destination')+': '+
                                    ((rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].Destination : '');

    //*******************VesselImoVesselFlag***********************//
    lineaActual_C5++;
    worksheet.mergeCells("A"+lineaActual_C5+":G"+lineaActual_C5);
    let cellVesselImoVesselFlag = worksheet.getCell("A"+lineaActual_C5);

    cellVesselImoVesselFlag.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    }
    cellVesselImoVesselFlag.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellVesselImoVesselFlag.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellVesselImoVesselFlag.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    cellVesselImoVesselFlag.value = this.$t('label.IMO')+' NO.: '+
                                    ((rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].VesselImo : '') +' / '+
                                    this.$t('label.flag')+': '+
                                    ((rowData[0].VisitDates_C5.length>0) ? rowData[0].VisitDates_C5[0].VesselFlag : '');

    /****************************************************************/
    /*****************CUADRO 6: STOPPED TIME*************************/
    /****************************************************************/
    worksheet.addRow();
    columnsDefs = Object.keys(rowData[0].StoppedTime_C6[0]);
    keyColumns = columnsDefs;
    data = rowData[0].StoppedTime_C6;
    headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true,
    };

    let j = 0;
    columnsDefs.map(function (data) {
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
            long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        worksheet.getColumn(j + 1).width = long;
        let cell = headerRow.getCell(j + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'AEAAAA'
            }
        }
        cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        cell.value = data.replace("_"," ");
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        j++;
    })

    rowData[0].StoppedTime_C6.map(async function (data) {
    let dataRow = worksheet.addRow();
    let indice = 0;

        keyColumns.map(function (data2) {
            let cell = dataRow.getCell(indice + 1);
            cell.value = data[data2];
            
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true,
            };
            if (indice!=0 && (cell.col == 3 )){
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'cccccc' },
                }
            }else{
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'AEAAAA' },
                }
            }
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            indice++;
        })
    })

    let lineaActual_C6 = lineaActual_C5+2;
    for(let k = 0; k <= rowData[0].StoppedTime_C6.length; k++){
        worksheet.mergeCells("A"+lineaActual_C6+":B"+lineaActual_C6); 
        lineaActual_C6++;
    }

    /****************************************************************/
    /*****************CUADRO 7: OPERATIONS TIME**********************/
    /****************************************************************/
    let lineaActual_C7 = lineaActual_C6 + 1;

    //*******************total Time Of Operations*******************//
    worksheet.mergeCells("A"+lineaActual_C7+":B"+lineaActual_C7);
    let cellTotalOperationsTime = worksheet.getCell("A"+lineaActual_C7);

    cellTotalOperationsTime.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellTotalOperationsTime.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellTotalOperationsTime.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellTotalOperationsTime.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellTotalOperationsTime.value = this.$t('label.totalTimeOfOperations')+': ';

    worksheet.mergeCells("C"+lineaActual_C7+":E"+lineaActual_C7);
    let cellTotalOperationsTimeValue = worksheet.getCell("C"+lineaActual_C7);

    cellTotalOperationsTimeValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellTotalOperationsTimeValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellTotalOperationsTimeValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellTotalOperationsTimeValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellTotalOperationsTimeValue.value = (rowData[0].OperationsTime_C7.length>0) ? rowData[0].OperationsTime_C7[0].TotalOperationsTime : '';    

    //*******************Net Operations Time***********************//
    lineaActual_C7++;
    worksheet.mergeCells("A"+lineaActual_C7+":B"+lineaActual_C7);
    let cellNetOperationsTime = worksheet.getCell("A"+lineaActual_C7);

    cellNetOperationsTime.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellNetOperationsTime.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellNetOperationsTime.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellNetOperationsTime.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellNetOperationsTime.value = this.$t('label.netTimeOfOperations')+': ';

    worksheet.mergeCells("C"+lineaActual_C7+":E"+lineaActual_C7);
    let cellNetOperationsTimeValue = worksheet.getCell("C"+lineaActual_C7);

    cellNetOperationsTimeValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellNetOperationsTimeValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellNetOperationsTimeValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellNetOperationsTimeValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellNetOperationsTimeValue.value = (rowData[0].OperationsTime_C7.length>0) ? rowData[0].OperationsTime_C7[0].NetOperationsTime : '';

    /****************************************************************/
    /********************CUADRO 8: MOVEMENTS*************************/
    /****************************************************************/
    worksheet.addRow();
    columnsDefs = Object.keys(rowData[0].Movements_C8[0]);
    keyColumns = columnsDefs;
    data = rowData[0].Movements_C8;
    headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true,
    };

    j = 0;
    columnsDefs.map(function (data) {
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
            long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        worksheet.getColumn(j + 1).width = long;
        let cell = headerRow.getCell(j + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'AEAAAA'
            }
        }
        cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        cell.value = data.replace("_"," ");
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        j++;
    })

    rowData[0].Movements_C8.map(async function (data) {
    let dataRow = worksheet.addRow();
    let indice = 0;

        keyColumns.map(function (data2) {
            let cell = dataRow.getCell(indice + 1);
            cell.value = data[data2];
            
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true,
            };

            
            if (indice!=0){
                /*if ( cell.col >= 3 && cell.col <= 5 ){
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'cccccc' },
                    }
                }*/
                if ( !isNaN(data[data2]) && cell.row == 5 ) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
            }else{
               /* cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'AEAAAA' },
                }*/
            }
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            indice++;
        })
    })

    let lineaActual_C8 = lineaActual_C7+2;
    for(let k = 0; k <= rowData[0].Movements_C8.length; k++){
        worksheet.mergeCells("A"+lineaActual_C8+":B"+lineaActual_C8); 
        lineaActual_C8++;
    }

    /****************************************************************/
    /*****************CUADRO 9: PRODUCTIVITY TIME********************/
    /****************************************************************/
    let lineaActual_C9 = lineaActual_C8 + 1;

    //*******************Productivity Gross*******************//
    worksheet.mergeCells("A"+lineaActual_C9+":C"+lineaActual_C9);
    let cellProductivityGross = worksheet.getCell("A"+lineaActual_C9);

    cellProductivityGross.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellProductivityGross.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellProductivityGross.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellProductivityGross.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellProductivityGross.value = this.$t('label.grossProductivityWithLostTime')+': ';

    worksheet.mergeCells("D"+lineaActual_C9+":E"+lineaActual_C9);
    let cellProductivityGrossValue = worksheet.getCell("D"+lineaActual_C9);

    cellProductivityGrossValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellProductivityGrossValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellProductivityGrossValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellProductivityGrossValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellProductivityGrossValue.value = (rowData[0].Productivity_C9.length>0) ? rowData[0].Productivity_C9[0].ProductivityGross : '';    

    //*******************Productivity Net***********************//
    lineaActual_C9++;
    worksheet.mergeCells("A"+lineaActual_C9+":C"+lineaActual_C9);
    let cellProductivityNet = worksheet.getCell("A"+lineaActual_C9);

    cellProductivityNet.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellProductivityNet.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellProductivityNet.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellProductivityNet.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellProductivityNet.value = this.$t('label.netProductivityWithoutLostTime')+': ';

    worksheet.mergeCells("D"+lineaActual_C9+":E"+lineaActual_C9);
    let cellProductivityNetValue = worksheet.getCell("D"+lineaActual_C9);

    cellProductivityNetValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellProductivityNetValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellProductivityNetValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellProductivityNetValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellProductivityNetValue.value = (rowData[0].Productivity_C9.length>0) ? rowData[0].Productivity_C9[0].ProductivityNet : '';

    //*******************Productivity Berth***********************//
    lineaActual_C9++;
    worksheet.mergeCells("A"+lineaActual_C9+":C"+lineaActual_C9);
    let cellProductivityBerth = worksheet.getCell("A"+lineaActual_C9);

    cellProductivityBerth.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellProductivityBerth.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'AEAAAA'
        }
    }
    cellProductivityBerth.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellProductivityBerth.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellProductivityBerth.value = this.$t('label.berthProductivityWithLostTime')+': ';

    worksheet.mergeCells("D"+lineaActual_C9+":E"+lineaActual_C9);
    let cellProductivityBerthValue = worksheet.getCell("D"+lineaActual_C9);

    cellProductivityBerthValue.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
    };
    cellProductivityBerthValue.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'CCCCCC'
        }
    }
    cellProductivityBerthValue.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    cellProductivityBerthValue.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    cellProductivityBerthValue.value = (rowData[0].Productivity_C9.length>0) ? rowData[0].Productivity_C9[0].ProductivityBerth : '';

    /****************************************************************/
    /******************CUADRO 10: CRANE DETAILS**********************/
    /****************************************************************/
    if(rowData[0].CraneDetails_C10 != ''){ 
    let lineaActual_C10 = lineaActual_C9+2;

        let longitudCraneDetails_C10 = rowData[0].CraneDetails_C10.length;
        let letraValorCraneDetails_C10=1;
        
        for (let  i = 0; i < longitudCraneDetails_C10; i++) { 
            worksheet.getColumn(letraValorCraneDetails_C10).width = 20;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].CraneAlias;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].BeginDischargeDate;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].EndDischargeDate;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].BeginLoadingDate;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].EndOperationsDate;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].CraneTime;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].DelayTime;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].NetTime;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].Discharged;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].Movilized;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].Loaded;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].HatchCoverTwinBox;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            lineaActual_C10++;

            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).value =  rowData[0].CraneDetails_C10[i].Total;
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true
            };
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            worksheet.getRow(lineaActual_C10).getCell(letraValorCraneDetails_C10).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            lineaActual_C10++;

            letraValorCraneDetails_C10++;
            lineaActual_C10 = lineaActual_C9+2;
        }

        let lineaActual_C10_start = lineaActual_C9+2;
        for (let  i = 0; i < 13; i++) { 
            worksheet.mergeCells("A"+lineaActual_C10_start+":B"+lineaActual_C10_start);
            worksheet.getCell("A"+lineaActual_C10_start).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            lineaActual_C10_start++;
        }
    }

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    workbook.xlsx.writeBuffer()
    .then(buffer => {
        saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
        this.$notify({
            group: 'container',
            text: this.$t('label.reportSuccessfully'),
            type: "success"
        });
    })
    this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
  }


export default {
  data,
  methods: {
    getPdf,
    getPdfArray,
    getExcel,
    getOrdersExcel,
    getExcelInspection,
    getExcelArray,
    getOperationalReport,
    getTimeReport,
    numeroLetra,
    getDamageReport,
    getExcelTransactionReversals,
    getOperationsSummary,
    orderMetalScrapPdf,
    getOperationalReportExcel,
    finalOperationsReport,
  },
  computed:{
    ...mapState({
        Visit: state => state.visitas.VisitId,
        dropItemReport: state => state.visitas.dropItemReport,       
        ItineraryId: state => state.visitas.ItineraryId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        user: state => state.auth.user,
        branch: state => state.auth.branch,
        apiStateForm: state => state.visitas.apiStateForm,

    }),
  },
}