import { required, maxLength, minLength, sameAs, not, helpers, } from 'vuelidate/lib/validators';
import { SoloContenedores, onlyAlphanumeric, validationSeal, onlyAlphanumeric3, SoloDecimales } from '@/_validations/validacionEspeciales'

const charValue = (char, special = false) =>{
    let values = {
        A:10,
        B:12,
        C:special?2:13,
        D:14,
        E:15,
        F:16,
        G:17,
        H:special?4:18,
        I:19,
        J:20,
        K:21,
        L:special?0:23,
        M:24,
        N:25,
        O:26,
        P:27,
        Q:28,
        R:29,
        S:30,
        T:31,
        U:special?9:32,
        V:34,
        W:35,
        X:36,
        Y:37,
        Z:38,
    }
    return values[char];
}
async function containerCodeValidation(text) {
    try {
        let chars = text.split('');
        const special = text.substr(0,3) == "HLCU";

        let charValues = await chars.map((element, index)=>{
            let value;
         
            
            if(index< 4){
                value = charValue(element, special);
            } else {
                value = parseInt(element);
            } 

            if( index == (chars.length-1))
                return value;
            
            value = parseInt(value * Math.pow(2, index));

            return value;
        });

        let plusValues = 0;

        await charValues.forEach((element, index) => {
            if (index<(charValues.length-1))
                plusValues = parseInt(plusValues+element);
        });
        
        let resultDevided = Math.floor(plusValues/11) ;
        let resultTimes = parseInt(resultDevided*11)
        let lastChar = parseInt(plusValues - resultTimes);

        

        if(lastChar == 10){
            lastChar = 0;
        }

        if (charValues[charValues.length-1] != lastChar){
            throw (`checkDigitInvalid`);
        } 
            
        return true;
    } catch (e){
        return false;
    }
}

export const StepOne = (FgShipperOwned, ValidatePortActivity) => {
    return {
        ContainerCode: {
            required,
            onlyAlphanumeric,
            SoloContenedores,
            checkDigit: containerCodeValidation,
            maxLength: maxLength(11),
            minLength: minLength(1),
        },
        Itinerary: {},
        PortActivity: ValidatePortActivity ? { required } : {},
        ShippingLine: { required },
        Status: { required },
        Iso: { required },
        Size: { required },
        Type: { required },
        Client: { },
        Imdg: {},
        ShipperOwned: FgShipperOwned ? { required } : {},
        Procedence: { required },
        Booking: {},
    }
}

export const StepTwo = (FgShipperOwned, PortActivity, limitWeight, limitVgm, StatusFull, plugged, dryReefer, fgApplyOversize, dimensions) => {
    let PortRequire = PortActivity ? required : true;
    let rangeRf = {}
    let SelectMeasureRf = {}
    let rangeRfUnity = {}
    let powerPlug = {}
    if(dryReefer && StatusFull){
        SelectMeasureRf = {
            required,
        }
        rangeRf = {
            required,
            minLength: minLength(1),
            maxLength: maxLength(3),
        }
        rangeRfUnity = {
            required,
            minLength: minLength(1),
            maxLength: maxLength(4),
            metricsWSNotReq
        }
    }

    if(dryReefer && StatusFull && plugged){
        powerPlug = {
            required,
        }
    }

    let sobredimention = {}
    let DinSelected = {}

    if(StatusFull && (fgApplyOversize || (parseFloat(dimensions.DimBack).toFixed(2)>0 || parseFloat(dimensions.DimLeft).toFixed(2)>0 || parseFloat(dimensions.DimRight).toFixed(2)>0 || parseFloat(dimensions.DimTop).toFixed(2)>0 || parseFloat(dimensions.DimFront).toFixed(2)>0 ))){
        sobredimention = {
            required,
            SoloDecimales,
            maxLength: maxLength(9),
        }

        DinSelected = {required}
    }
    else{
        sobredimention = {
            SoloDecimales,
            maxLength: maxLength(9),
        }
    }
    return {
        NewEntryForm: {
            ContainerCode: {
                required,
                maxLength: maxLength(11),
                minLength: minLength(1),
            },
            Itinerary: {},
            PortActivity: PortActivity ? { required } : {},
            ShippingLine: { required },
            Status: { required },
            Iso: { required },
            Size: { required },
            Type: { required },
            Client: {},
            Imdg: {},
            ShipperOwned: FgShipperOwned ? { required } : {},
            Procedence: { required },
            Booking: {},
        },
        containerClassSelected:{},
        conditionSelected: { required },
        loadPortSelected:{
            required: PortRequire,
            NotSameAsPLAndPOD: not(sameAs('dischargePortSelected'))
        },
        loadPort:{
            required: PortRequire,
            NotSameAsPLAndPOD: not(sameAs('dischargePortSelected'))
        },
        dischargePortSelected:{
            required: PortRequire,
            NotSameAsPLAndPOD: not(sameAs('loadPortSelected'))
        },
        dischargePort:{
            required: PortRequire,
            NotSameAsPLAndPOD: not(sameAs('loadPortSelected'))
        },
        TransshipmentPort:{},
        TransshipmentPortSelected:{},
        deliveryPortSelected: {
            NotSameLoadPortAndDeliveryPort: not(sameAs('cargoOriginPortSelected'))
        },
        deliveryPort: {
            NotSameLoadPortAndDeliveryPort: not(sameAs('cargoOriginPortSelected'))
        },
        cargoOriginPort: {
            NotSameLoadPortAndDeliveryPort: not(sameAs('deliveryPortSelected'))
        },
        cargoOriginPortSelected: {
            NotSameLoadPortAndDeliveryPort: not(sameAs('deliveryPortSelected'))
        },
        length:{
            required(Value){
                let ValueNumber = Number.parseFloat(((Value.toString())?.replace(/[.]/g, ''))?.replace(',', '.'))?.toFixed(2);
                return ValueNumber > 0;
            },
            maxValue(Value){
                let ValueNumber = Number.parseFloat(Value.toString()?.replace(/[.]/g, '')?.replace(',', '.'));
                return ValueNumber < 100000.00;
            },
        },
        lengthSelected:{
            required
        },
        heigth:{
            required(Value){
                let ValueNumber = Number.parseFloat(((Value.toString())?.replace(/[.]/g, ''))?.replace(',', '.'))?.toFixed(2);
                return ValueNumber > 0;
            },
            maxValue(Value){
                let ValueNumber = Number.parseFloat(Value.toString()?.replace(/[.]/g, '')?.replace(',', '.'));
                return ValueNumber < 100000.00;
            },
        },
        heigthSelected:{
            required
        },
        weight:{
            required(Value){
                let ValueNumber = Number.parseFloat(((Value.toString())?.replace(/[.]/g, ''))?.replace(',', '.'))?.toFixed(2);
                return ValueNumber > 0;
            },
            maxValue(Value){
                let ValueNumber = Number.parseFloat(Value.toString()?.replace(/[.]/g, '')?.replace(',', '.'));
                return ValueNumber < 100000.00;
            },
            limit(value) {
                if (value === '' || value === null || limitWeight === 0.00 || this.$store.state.yardManagement.loading) return true;
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        let valueReal = Number.parseFloat(value.toString()?.replace(/[.]/g, '')?.replace(',', '.'));
                        if(parseFloat(limitWeight) < parseFloat(valueReal)){
                            resolve(false)
                        }else{
                            resolve(true);
                        }
                    })
                }, 350 + Math.random() * 300)
            }   
        },
        weightSelected:{
            required
        },
        vgm: {
            maxValue(Value){
                let ValueNumber = Number.parseFloat(Value.toString()?.replace(/[.]/g, '')?.replace(',', '.'));
                return ValueNumber < 100000.00;
            },
            limit(value) {
                if (value === '' || value === null || limitVgm === null || this.$store.state.yardManagement.loading) return true
                
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        let valueReal = Number.parseFloat(value.toString()?.replace(/[.]/g, '')?.replace(',', '.'));
                        if(parseFloat(limitVgm) < parseFloat(valueReal)){
                            resolve(false)
                        }else{
                            resolve(true);
                        }
                    })
                }, 350 + Math.random() * 300)
            } 
        },
        vgmSelected:{},
        DescriptionType:{
            minLength: minLength(4),
            maxLength: maxLength(50)
        },
        measureUnitRfSelected: SelectMeasureRf,
        minimunRangeRf: rangeRfUnity,
        maximunRangeRf: rangeRfUnity,
        reeferSetting: rangeRf,
        tpPowerPlugSelected: powerPlug,
        measureUnitDinSelected: DinSelected,
        DimFront: sobredimention,
        DimBack: sobredimention ,
        DimLeft: sobredimention,
        DimRight: sobredimention,
        DimTop: sobredimention,
    }
}